import { KeyMeasure, TKeyMeasure } from '../api/analytics/KeyMeasure';
import React from 'react';
import { ITabNavigationItem } from '../layout/PageTabNavigationItems';
import { OptionsBuilderItemTypes } from '../types/dataframes/options-builder-item-types';
import { TDimension } from '../api/analytics/Dimension';
import { KeyMeasureFact, TKeyMeasureFact } from '../api/analytics/KeyMeasureFact';
import { TDimensionAttribute } from '../api/analytics/DimensionAttribute';
import { DropdownItem } from '@patternfly/react-core';
import { KeyMeasureFactOptions } from '../types/dataframes/dataframe-key-measure-fact-options';
import { DimensionOptions } from '../types/dataframes/dataframe-dimension-options';
import {
	TDataframe,
	TDataframeData,
	TDataframeEntry,
	TDataframeFilter,
	TDataframeOrder,
} from '../api/types';
import { DraggableMenuItemConfig, DraggableMenuItemData } from '../types/databuilder/databuilder';
import { generateGUID } from '../helpers/guid.helper';
import { DataBuilderZones } from '../enums/data-builder-zones';
import { TFolder } from '../api/types/folder';
import {
	TNewDataframe,
	TNewDataframeData,
	TNewDataframeEntry,
	TNewDataframeFilter,
	TNewDataframeOrder,
} from '../api/dataframes/Dataframes';
import { MetaDataFrame } from '../enums/meta-dataframe-name.enum';
import { generateRandomString } from '../helpers/random-string.helper';
import { SortTypes } from '../types/common/sort-types';
import { TUnitType } from '../api/analytics/UnitType';

export type LabeledDraggable = {
	label: string;
	items: ITabNavigationItem[];
};

export const useFactOptions = (
	isCalculatedFact = false,
	disableCalculatedFact = false,
	data: DraggableMenuItemConfig | null,
	hideConfigureCalculationOption?: boolean
): JSX.Element[] => {
	let dropdownItems = [
		<DropdownItem
			id={
				disableCalculatedFact
					? KeyMeasureFactOptions.CalculationInvalid
					: KeyMeasureFactOptions.Calculation
			}
			key={KeyMeasureFactOptions.Calculation}
			tooltip={
				disableCalculatedFact
					? 'You have newly dropped Facts, please save the Dataframe first before configuring Calculated Facts'
					: 'Configure Calculation'
			}
			className={disableCalculatedFact ? 'disabled-dropdown-item' : ''}
		>
			Configure Calculation
		</DropdownItem>,
		<DropdownItem
			id={KeyMeasureFactOptions.ChangeUnitType}
			key={KeyMeasureFactOptions.ChangeUnitType}
			tooltip="Change Unit Type"
		>
			Change Unit Type
		</DropdownItem>,
		<DropdownItem
			id={KeyMeasureFactOptions.Filters}
			key={KeyMeasureFactOptions.Filters}
			tooltip="Filters"
		>
			Filters
		</DropdownItem>,
		<DropdownItem
			id={KeyMeasureFactOptions.Options}
			key={KeyMeasureFactOptions.Options}
			tooltip="Options"
		>
			Options
		</DropdownItem>,
		<DropdownItem
			id={KeyMeasureFactOptions.RemoveFact}
			key={KeyMeasureFactOptions.RemoveFact}
			tooltip="Remove"
		>
			Remove
		</DropdownItem>,
	];

	if (isCalculatedFact == false || hideConfigureCalculationOption) {
		dropdownItems = dropdownItems.filter((x) => x.key != KeyMeasureFactOptions.Calculation);
	}

	return dropdownItems;
};

export const useDimensionOptions = (
	item?: DraggableMenuItemData,
	breakoutGroups?: boolean
): JSX.Element[] => {
	return [
		<DropdownItem
			id={DimensionOptions.Options}
			key={DimensionOptions.Options}
			tooltip="Options"
		>
			Options
		</DropdownItem>,
		...(breakoutGroups && item
			? [
					<DropdownItem
						id={DimensionOptions.BreakoutGroups}
						key={DimensionOptions.BreakoutGroups}
						tooltip={
							item.data?.totaled
								? 'Remove from Breakout Groups'
								: 'Add to Breakout Groups'
						}
					>
						{item.data?.totaled
							? 'Remove from Breakout Groups'
							: 'Add to Breakout Groups'}
					</DropdownItem>,
			  ]
			: []),
		<DropdownItem
			id={DimensionOptions.RemoveDimension}
			key={DimensionOptions.RemoveDimension}
			tooltip="Remove"
		>
			Remove
		</DropdownItem>,
	];
};

export const populateDataForFact = (
	fact: TKeyMeasureFact,
	unitType?: TUnitType
): DraggableMenuItemConfig => {
	return {
		id: fact.id,
		title: fact.name,
		unitType: fact.unitType as number,
		numDecimals:
			unitType?.precision !== undefined && unitType?.precision !== null
				? unitType.precision
				: 2,
		defaultWhenNull: '***',
		hidden: false,
		isVisible: true,
		totaled: false,
	};
};

export const populateDataForCalcFact = (
	fact: TDataframeData,
	unitType?: TUnitType
): DraggableMenuItemConfig => {
	return {
		id: fact.id,
		title: fact.title ?? fact.alias ?? '',
		unitType: fact.unitType as number,
		numDecimals: unitType?.precision ? unitType.precision : 2,
		defaultWhenNull: '***',
		hidden: false,
		isVisible: true,
		totaled: false,
		alias: fact.alias,
		calculated: true,
		formula_operation: fact.formula_operation,
		formula_variable_1: fact.formula_variable_1,
		formula_variable_2: fact.formula_variable_2,
	};
};

export const populateDataForDimension = (dimension: TDimension): DraggableMenuItemConfig => {
	return {
		id: dimension.id,
		title: dimension.name ?? dimension.display_name,
		useSequence: false,
		drillable: false,
		isVisible: true,
		totaled: false,
	};
};

export const populateDataForDimensionAttribute = (
	attr: TDimensionAttribute | TDimension
): DraggableMenuItemConfig => {
	return {
		id: attr.id,
		title: attr.name,
		useSequence: false,
		drillable: false,
		isVisible: true,
		totaled: false,
	};
};

export const populateDataForCalculatedFact = (
	measure: TKeyMeasure,
	unitType?: TUnitType
): DraggableMenuItemConfig => {
	return {
		id: undefined,
		title: measure.display_name ?? measure.name,
		unitType: measure.unitType as number,
		numDecimals: unitType?.precision ? unitType.precision : 2,
		defaultWhenNull: '***',
		hidden: false,
		isVisible: true,
		totaled: false,
	};
};

export const populateDroppedFactsByDataframe = (
	dataframe: TDataframe,
	measures: TKeyMeasure[]
): DraggableMenuItemData[] => {
	const factsToSet: DraggableMenuItemData[] = [];

	dataframe.datasets.forEach((_: TDataframeData) => {
		let factToUse = {};

		measures.forEach((measure) => {
			const fact = measure.keyMeasureFacts.find((fact) => fact.id == _.keyMeasureFact);
			if (fact) {
				factToUse = fact;
			}
		});

		const factFilter = dataframe.filters.find(
			(filter) =>
				filter.entity_id == _.keyMeasureFact &&
				filter.entity_type === OptionsBuilderItemTypes.KeyMeasureFact
		);

		factsToSet.push({
			id: generateGUID(),
			entity: factToUse,
			data: {
				id: _.calculated ? _.id : _.keyMeasureFact,
				title: _.title ?? '',
				unitType: _.unitType as number,
				numDecimals: _.numDecimals,
				defaultWhenNull: '***',
				hidden: _.hidden,
				isVisible: true,
				totaled: false,
				alias: _.alias,
				calculated: _.calculated,
				formula_operation: _.formula_operation,
				formula_variable_1: _.formula_variable_1,
				formula_variable_2: _.formula_variable_2,
				...(factFilter && { operator: factFilter.operator }),
				...(factFilter && { value: factFilter.value }),
			},
			entityType: OptionsBuilderItemTypes.KeyMeasureFact,
			itemType: 'dropdown',
			allowedZones: [DataBuilderZones.facts],
			static: false,
			icon: 'database',
		});
	});

	return factsToSet;
};

export const populateDroppedRowsByDataframe = (
	dataframe: TDataframe,
	dimensions: TDimension[]
): DraggableMenuItemData[] => {
	const dimsToSet: DraggableMenuItemData[] = [];

	dataframe.rowEntry.forEach((_: TDataframeEntry) => {
		let dimToUse: TDimension | TDimensionAttribute | undefined = undefined;

		if (
			_.entity_type === OptionsBuilderItemTypes.Dimension ||
			_.entity_type === OptionsBuilderItemTypes.DateSeries
		) {
			dimToUse = dimensions.find((dim) => dim.id === _.entity_id);

			dimsToSet.push({
				id: generateGUID(),
				entity: dimToUse,
				data: {
					id: _.entity_id,
					title: _.title ?? '',
					drillable: _.drillable,
					isVisible: true,
					totaled: _.totaled,
					...(_.entity_type === OptionsBuilderItemTypes.DateSeries && {
						previousPeriods: _.previous_periods,
					}),
					...(_.entity_type === OptionsBuilderItemTypes.DateSeries && {
						futurePeriods: _.future_periods,
					}),
				},
				entityType: _.entity_type,
				itemType: 'dropdown',
				allowedZones: [
					DataBuilderZones.rows,
					DataBuilderZones.columns,
					DataBuilderZones.filters,
					DataBuilderZones.drills,
				],
				static: false,
				icon: 'dataSource',
			});
		} else if (_.entity_type === OptionsBuilderItemTypes.DimensionAttribute) {
			dimensions.forEach((dim) => {
				const dimAttr = dim.dimensionAttributes.find((attr) => attr.id === _.entity_id);
				if (dimAttr) {
					dimToUse = dimAttr;
				}
			});

			dimsToSet.push({
				id: generateGUID(),
				entity: dimToUse,
				data: {
					id: _.entity_id,
					title: _.title ?? '',
					drillable: _.drillable,
					isVisible: true,
					totaled: _.totaled,
				},
				entityType: _.entity_type,
				itemType: 'dropdown',
				allowedZones: [
					DataBuilderZones.rows,
					DataBuilderZones.columns,
					DataBuilderZones.filters,
					DataBuilderZones.drills,
				],
				static: false,
				icon: 'dataSource',
			});
		}
	});

	return dimsToSet;
};

export const populateDroppedColumnsByDataframe = (
	dataframe: TDataframe,
	dimensions: TDimension[]
): DraggableMenuItemData[] => {
	const dimsToSet: DraggableMenuItemData[] = [];

	dataframe.columnEntry.forEach((_: TDataframeEntry) => {
		let dimToUse: TDimension | TDimensionAttribute | undefined = undefined;

		if (
			_.entity_type === OptionsBuilderItemTypes.Dimension ||
			_.entity_type === OptionsBuilderItemTypes.DateSeries
		) {
			dimToUse = dimensions.find((dim) => dim.id === _.entity_id);

			dimsToSet.push({
				id: generateGUID(),
				entity: dimToUse,
				data: {
					id: _.entity_id,
					title: _.title ?? '',
					drillable: _.drillable,
					isVisible: true,
					totaled: _.totaled,
					...(_.entity_type === OptionsBuilderItemTypes.DateSeries && {
						previousPeriods: _.previous_periods,
					}),
					...(_.entity_type === OptionsBuilderItemTypes.DateSeries && {
						futurePeriods: _.future_periods,
					}),
				},
				entityType: _.entity_type,
				itemType: 'dropdown',
				allowedZones: [
					DataBuilderZones.rows,
					DataBuilderZones.columns,
					DataBuilderZones.filters,
				],
				static: false,
				icon: 'dataSource',
			});
		} else if (_.entity_type === OptionsBuilderItemTypes.DimensionAttribute) {
			dimensions.forEach((dim) => {
				const dimAttr = dim.dimensionAttributes.find((attr) => attr.id === _.entity_id);
				if (dimAttr) {
					dimToUse = dimAttr;
				}
			});

			dimsToSet.push({
				id: generateGUID(),
				entity: dimToUse,
				data: {
					id: _.entity_id,
					title: _.title ?? '',
					drillable: _.drillable,
					isVisible: true,
					totaled: _.totaled,
				},
				entityType: _.entity_type,
				itemType: 'dropdown',
				allowedZones: [
					DataBuilderZones.rows,
					DataBuilderZones.columns,
					DataBuilderZones.filters,
				],
				static: false,
				icon: 'dataSource',
			});
		}
	});

	return dimsToSet;
};

export const populateDroppedFiltersByDataframe = (
	dataframe: TDataframe,
	dimensions: TDimension[]
): DraggableMenuItemData[] => {
	const dimsToSet: DraggableMenuItemData[] = [];

	dataframe.filters.forEach((_: TDataframeFilter) => {
		let dimToUse: TDimension | TDimensionAttribute | undefined = undefined;

		if (_.entity_type === OptionsBuilderItemTypes.Dimension) {
			dimToUse = dimensions.find((dim) => dim.id === _.entity_id);

			dimsToSet.push({
				id: generateGUID(),
				entity: dimToUse,
				data: {
					id: _.entity_id,
					title: dimToUse ? dimToUse.name : '',
					excluded: _.excluded,
					operator: _.operator,
					isExistingValue: _.isExistingValue,
					value: _.value,
					isVisible: true,
					totaled: false,
				},
				entityType: _.entity_type,
				itemType: 'button',
				allowedZones: [
					DataBuilderZones.rows,
					DataBuilderZones.columns,
					DataBuilderZones.filters,
					DataBuilderZones.drills,
				],
				static: false,
			});
		} else if (_.entity_type === OptionsBuilderItemTypes.DimensionAttribute) {
			dimensions.forEach((dim) => {
				const dimAttr = dim.dimensionAttributes.find((attr) => attr.id === _.entity_id);
				if (dimAttr) {
					dimToUse = dimAttr;
				}
			});

			dimsToSet.push({
				id: generateGUID(),
				entity: dimToUse,
				data: {
					id: _.entity_id,
					title: dimToUse ? dimToUse['name'] : '',
					excluded: _.excluded,
					operator: _.operator,
					isExistingValue: _.isExistingValue,
					value: _.value,
					isVisible: true,
					totaled: false,
				},
				entityType: _.entity_type,
				itemType: 'button',
				allowedZones: [
					DataBuilderZones.rows,
					DataBuilderZones.columns,
					DataBuilderZones.filters,
					DataBuilderZones.drills,
				],
				static: false,
			});
		}
	});

	return dimsToSet;
};

export const populateDroppedFactsByKeyMeasure = (
	measureId: number,
	measureFactId: number,
	measures: TKeyMeasure[],
	unitTypes?: TUnitType[]
): DraggableMenuItemData[] => {
	const factsToSet: DraggableMenuItemData[] = [];

	const measure = measures.find((measure) => measure.id === measureId);

	if (measure) {
		const fact = measure.keyMeasureFacts.find((fact) => fact.id == measureFactId);

		if (fact) {
			let unitType = undefined;
			if (unitTypes) {
				unitType = unitTypes.find((u) => u.id === fact.unitType);
			}
			factsToSet.push({
				id: generateGUID(),
				entity: fact,
				data: populateDataForFact(fact, unitType),
				entityType: OptionsBuilderItemTypes.KeyMeasureFact,
				itemType: 'dropdown',
				allowedZones: [DataBuilderZones.facts],
				static: false,
				icon: 'database',
			});
		}
	}

	return factsToSet;
};

export const populateDroppedRowsByKeyMeasure = (
	dimensions: TDimension[],
	dimensionId?: number,
	dimensionAttributeId?: number
): DraggableMenuItemData[] => {
	const dimsToSet: DraggableMenuItemData[] = [];

	if (!dimensionId && !dimensionAttributeId) {
		dimsToSet.push({
			id: generateGUID(),
			entity: { id: 0 },
			data: {
				id: 0,
				title: 'Date Range',
				useSequence: false,
				drillable: false,
				isVisible: true,
				totaled: false,
			},
			entityType: OptionsBuilderItemTypes.DateSeries,
			itemType: 'date',
			allowedZones: [
				DataBuilderZones.rows,
				DataBuilderZones.columns,
				DataBuilderZones.filters,
				DataBuilderZones.drills,
			],
			static: false,
			icon: 'dataSource',
		});
	} else if (dimensionId) {
		const dim = dimensions.find((dim) => dim.id === dimensionId);
		if (dim) {
			dimsToSet.push({
				id: generateGUID(),
				entity: dim,
				data: populateDataForDimension(dim),
				entityType: OptionsBuilderItemTypes.Dimension,
				itemType: 'dropdown',
				allowedZones: [
					DataBuilderZones.rows,
					DataBuilderZones.columns,
					DataBuilderZones.filters,
					DataBuilderZones.drills,
				],
				static: false,
				icon: 'dataSource',
			});
		}
	} else if (dimensionAttributeId) {
		const dim = dimensions.find((dim) =>
			dim.dimensionAttributes.some((dimAttr) => dimAttr.id === dimensionAttributeId)
		);

		if (dim) {
			const dimAttr = dim.dimensionAttributes.find(
				(dimAtt) => dimAtt.id === dimensionAttributeId
			);

			if (dimAttr) {
				dimsToSet.push({
					id: generateGUID(),
					entity: dimAttr,
					data: populateDataForDimensionAttribute(dimAttr),
					entityType: OptionsBuilderItemTypes.DimensionAttribute,
					itemType: 'dropdown',
					allowedZones: [
						DataBuilderZones.rows,
						DataBuilderZones.columns,
						DataBuilderZones.filters,
						DataBuilderZones.drills,
					],
					static: false,
					icon: 'dataSource',
				});
			}
		}
	}

	return dimsToSet;
};

export const populateDroppedFactsByKeyMeasureFact = (
	measureFactId: number,
	measures: TKeyMeasure[],
	unitTypes?: TUnitType[]
): DraggableMenuItemData[] => {
	const factsToSet: DraggableMenuItemData[] = [];

	const measuresToUse = measures.filter((measure: TKeyMeasure) => {
		return measure.keyMeasureFacts.some((fact: TKeyMeasureFact) => {
			return fact.id === measureFactId;
		});
	});

	const keyMeasureFactsToUse = measuresToUse.flatMap((measure) => measure.keyMeasureFacts);

	keyMeasureFactsToUse.forEach((fact) => {
		let unitType = undefined;
		if (unitTypes) {
			unitType = unitTypes.find((u) => u.id === fact.unitType);
		}
		factsToSet.push({
			id: generateGUID(),
			entity: fact,
			data: populateDataForFact(fact, unitType),
			entityType: OptionsBuilderItemTypes.KeyMeasureFact,
			itemType: 'dropdown',
			allowedZones: [DataBuilderZones.facts],
			static: false,
			icon: 'database',
		});
	});

	return factsToSet;
};

export const populateDroppedRowsByKeyMeasureFact = (
	measureFactId: number,
	measures: TKeyMeasure[],
	dimensions: TDimension[]
): DraggableMenuItemData[] => {
	const dimsToSet: DraggableMenuItemData[] = [];

	const measuresToUse = measures.filter((measure: TKeyMeasure) => {
		return measure.keyMeasureFacts.some((fact: TKeyMeasureFact) => {
			return fact.id === measureFactId;
		});
	});

	const measureIds = measuresToUse.map((measure: TKeyMeasure) => measure.id);

	dimensions.map((dimension: TDimension): void => {
		dimension.keyMeasures.map((measure: TKeyMeasure): void => {
			if (measureIds.includes(measure.id)) {
				dimsToSet.push({
					id: generateGUID(),
					entity: dimension,
					data: populateDataForDimension(dimension),
					entityType: OptionsBuilderItemTypes.Dimension,
					itemType: 'dropdown',
					allowedZones: [
						DataBuilderZones.rows,
						DataBuilderZones.columns,
						DataBuilderZones.filters,
						DataBuilderZones.drills,
					],
					static: false,
					icon: 'dataSource',
				});

				if (dimension.dimensionAttributes.length) {
					dimension.dimensionAttributes.forEach((dimAttr) => {
						dimsToSet.push({
							id: generateGUID(),
							entity: dimAttr,
							data: populateDataForDimensionAttribute(dimAttr),
							entityType: OptionsBuilderItemTypes.DimensionAttribute,
							itemType: 'dropdown',
							allowedZones: [
								DataBuilderZones.rows,
								DataBuilderZones.columns,
								DataBuilderZones.filters,
								DataBuilderZones.drills,
							],
							static: false,
							icon: 'dataSource',
						});
					});
				}
			}
		});
	});
	console.log('1', { dimensions, dimsToSet });

	return dimsToSet;
};

export const populateDroppedFilterByDimension = (
	id: number,
	dimensions: TDimension[]
): DraggableMenuItemData | null => {
	let dimToSet: DraggableMenuItemData | null = null;

	const dim = dimensions.find((dim) => dim.id === id);

	if (dim) {
		dimToSet = {
			id: generateGUID(),
			entity: dim,
			data: populateDataForDimension(dim),
			entityType: OptionsBuilderItemTypes.Dimension,
			itemType: 'button',
			allowedZones: [
				DataBuilderZones.rows,
				DataBuilderZones.columns,
				DataBuilderZones.filters,
				DataBuilderZones.drills,
			],
			static: false,
		};
	}

	return dimToSet;
};

export const populateDroppedFilterByDimensionAttribute = (
	id: number,
	dimensions: TDimension[]
): DraggableMenuItemData | null => {
	let dimToSet: DraggableMenuItemData | null = null;

	dimensions.forEach((dim) => {
		const attr = dim.dimensionAttributes.find((attr) => attr.id === id);
		if (attr) {
			dimToSet = {
				id: generateGUID(),
				entity: attr,
				data: populateDataForDimensionAttribute(attr),
				entityType: OptionsBuilderItemTypes.DimensionAttribute,
				itemType: 'button',
				allowedZones: [
					DataBuilderZones.rows,
					DataBuilderZones.columns,
					DataBuilderZones.filters,
					DataBuilderZones.drills,
				],
				static: false,
			};
		}
	});

	return dimToSet;
};

export const populateDroppedRowByDimension = (
	id: number,
	dimensions: TDimension[]
): DraggableMenuItemData | null => {
	let dimToSet: DraggableMenuItemData | null = null;

	const dim = dimensions.find((dim) => dim.id === id);

	if (dim) {
		dimToSet = {
			id: generateGUID(),
			entity: dim,
			data: populateDataForDimension(dim),
			entityType: OptionsBuilderItemTypes.Dimension,
			itemType: 'dropdown',
			allowedZones: [
				DataBuilderZones.rows,
				DataBuilderZones.columns,
				DataBuilderZones.filters,
				DataBuilderZones.drills,
			],
			static: false,
			icon: 'dataSource',
		};
	}

	return dimToSet;
};

export const populateDroppedRowByDimensionAttribute = (
	id: number,
	dimensions: TDimension[]
): DraggableMenuItemData | null => {
	let dimToSet: DraggableMenuItemData | null = null;

	dimensions.forEach((dim) => {
		const attr = dim.dimensionAttributes.find((attr) => attr.id === id);
		if (attr) {
			dimToSet = {
				id: generateGUID(),
				entity: attr,
				data: populateDataForDimensionAttribute(attr),
				entityType: OptionsBuilderItemTypes.DimensionAttribute,
				itemType: 'dropdown',
				allowedZones: [
					DataBuilderZones.rows,
					DataBuilderZones.columns,
					DataBuilderZones.filters,
					DataBuilderZones.drills,
				],
				static: false,
				icon: 'dataSource',
			};
		}
	});

	return dimToSet;
};

export const populateDefaultDatRangeDim = (): DraggableMenuItemData => {
	return {
		id: generateGUID(),
		entity: { id: 0 },
		data: {
			id: 0,
			title: 'Date Range',
			useSequence: false,
			drillable: false,
			isVisible: true,
			totaled: false,
		},
		entityType: OptionsBuilderItemTypes.DateSeries,
		itemType: 'dropdown',
		allowedZones: [DataBuilderZones.rows, DataBuilderZones.columns, DataBuilderZones.filters],
		static: false,
		icon: 'dataSource',
	};
};

const getExistingFactId = (
	dataframe: TDataframe,
	item: DraggableMenuItemData
): number | undefined => {
	const fact = dataframe?.datasets.find((set): boolean => set.keyMeasureFact === item.data?.id);
	if (fact) {
		return fact.id;
	}
};

const getExistingRowId = (
	dataframe: TDataframe,
	item: DraggableMenuItemData
): number | undefined => {
	const field = dataframe?.rowEntry.find(
		(set): boolean => set.entity_id === item.data?.id && set.entity_type === item.entityType
	);
	if (field) {
		return field.id;
	}
};

const getExistingColumnId = (
	dataframe: TDataframe,
	item: DraggableMenuItemData
): number | undefined => {
	const field = dataframe?.columnEntry.find(
		(set): boolean => set.entity_id === item.data?.id && set.entity_type === item.entityType
	);
	if (field) {
		return field.id;
	}
};

const getExistingFilterId = (
	dataframe: TDataframe,
	item: DraggableMenuItemData
): number | undefined => {
	const filter = dataframe?.filters.find(
		(set): boolean => set.entity_id === item.data?.id && set.entity_type === item.entityType
	);
	if (filter) {
		return filter.id;
	}
};

export const getUnitTypeIdByFactId = (measures: KeyMeasure[], factId: number) => {
	let fact: KeyMeasureFact | undefined;
	measures.forEach((measure) => {
		const factToUse = measure.keyMeasureFacts.find((f) => f.id === factId);
		if (factToUse) {
			fact = factToUse;
		}
	});

	if (fact) {
		return fact.unitType as number;
	}
};

export const buildDataframeRequest = (
	facts: DraggableMenuItemData[],
	rows: DraggableMenuItemData[],
	columns: DraggableMenuItemData[],
	filters: DraggableMenuItemData[],
	name: string,
	order: TDataframeOrder | TNewDataframeOrder | undefined,
	isChild: boolean,
	isPatch: boolean,
	existingDataframe?: TDataframe,
	folder?: TFolder
): TDataframe | TNewDataframe => {
	return {
		...(existingDataframe && isPatch && { id: existingDataframe.id }),
		parent: isChild ? existingDataframe?.id : existingDataframe?.parent,
		name: name != '' ? name : MetaDataFrame.Name + generateRandomString(5),
		datasets: facts.map((fact: DraggableMenuItemData): TNewDataframeData | TDataframeData => {
			return {
				...(existingDataframe &&
					isPatch &&
					getExistingFactId(existingDataframe, fact) && {
						id: getExistingFactId(existingDataframe, fact),
					}),
				keyMeasureFact: fact.data?.id,
				title: fact.data?.title ?? '',
				numDecimals: fact.data?.numDecimals ?? 0,
				hidden: fact.data?.hidden ?? false,
				unitType: fact.data?.unitType ?? 0,
				alias: fact.data?.alias,
				calculated: fact.data?.calculated,
				formula_operation: fact.data?.formula_operation,
				formula_variable_1: fact.data?.formula_variable_1,
				formula_variable_2: fact.data?.formula_variable_2,
				sequence: fact.data?.sequence,
			};
		}),
		folder: folder?.id,
		rowEntry: rows.map((row: DraggableMenuItemData): TNewDataframeEntry | TDataframeEntry => {
			return {
				...(existingDataframe &&
					isPatch &&
					getExistingRowId(existingDataframe, row) && {
						id: getExistingRowId(existingDataframe, row),
					}),
				entity_type: row.entityType,
				entity_id: row.data?.id ?? 0,
				title: row.data?.title ?? '',
				drillable: true,
				totaled: row.data?.totaled ?? false,
				...(row.entityType === OptionsBuilderItemTypes.DateSeries && {
					previous_periods: row.data?.previousPeriods,
				}),
				...(row.entityType === OptionsBuilderItemTypes.DateSeries && {
					future_periods: row.data?.futurePeriods,
				}),
			};
		}),
		columnEntry: columns.map(
			(column: DraggableMenuItemData): TNewDataframeEntry | TDataframeEntry => {
				return {
					...(existingDataframe &&
						isPatch &&
						getExistingColumnId(existingDataframe, column) && {
							id: getExistingColumnId(existingDataframe, column),
						}),
					entity_type: column.entityType,
					entity_id: column.data?.id ?? 0,
					title: column.data?.title ?? '',
					drillable: true,
					totaled: column.data?.totaled ?? false,
					...(column.entityType === OptionsBuilderItemTypes.DateSeries && {
						previous_periods: column.data?.previousPeriods,
					}),
					...(column.entityType === OptionsBuilderItemTypes.DateSeries && {
						future_periods: column.data?.futurePeriods,
					}),
				};
			}
		),
		filters: [
			...filters
				.filter((filter) => filter.entityType != OptionsBuilderItemTypes.DatePeriodSelector)
				.map((filter: DraggableMenuItemData): TNewDataframeFilter | TDataframeFilter => {
					return {
						...(existingDataframe &&
							isPatch &&
							getExistingFilterId(existingDataframe, filter) && {
								id: getExistingFilterId(existingDataframe, filter),
							}),
						entity_type: filter.entityType,
						entity_id: filter.data?.id ?? 0,
						...(filter.data?.value && { value: filter.data.value }),
						excluded: filter.data?.excluded ?? false,
						isExistingValue: filter.data?.isExistingValue ?? false,
						...(filter.data?.operator && { operator: filter.data.operator }),
					};
				}),
			...facts
				.filter(
					(fact: DraggableMenuItemData): boolean =>
						fact.data?.operator != void 0 && fact.data.value != void 0
				)
				.map((fact: DraggableMenuItemData): TNewDataframeFilter | TDataframeFilter => {
					return {
						...(existingDataframe &&
							isPatch &&
							getExistingFilterId(existingDataframe, fact) && {
								id: getExistingFilterId(existingDataframe, fact),
							}),
						entity_type: fact.entityType,
						entity_id: fact.data?.id ?? 0,
						...(fact.data?.value && { value: fact.data.value }),
						excluded: false,
						isExistingValue: false,
						...(fact.data?.operator && { operator: fact.data.operator }),
					};
				}),
		],
		...(order && { order: [buildOrderObject(facts, rows, columns, order)] }),
	};
};

const buildOrderObject = (
	facts: DraggableMenuItemData[],
	rows: DraggableMenuItemData[],
	columns: DraggableMenuItemData[],
	order: TDataframeOrder | TNewDataframeOrder | undefined
): TDataframeOrder | TNewDataframeOrder => {
	let exists = facts.some(
		(fact) =>
			(fact.data?.id ?? 0) === order?.entity_id && fact.entityType === order?.entity_type
	);

	if (!exists) {
		exists = rows.some(
			(row) => row.data?.id === order?.entity_id && row.entityType === order?.entity_type
		);
	}

	if (!exists) {
		exists = columns.some(
			(col) => col.data?.id === order?.entity_id && col.entityType === order?.entity_type
		);
	}

	if (!exists && facts.length && rows.length) {
		return {
			direction: SortTypes.Asc,
			entity_id: rows[0].data?.id ?? 0,
			entity_type: rows[0].entityType,
			useSequence: false,
		};
	}

	return order as TDataframeOrder | TNewDataframeOrder;
};
