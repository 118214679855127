import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router';
import { OutletContext } from '../../../layout/Layout';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import { OptionsBuilderItemTypes } from '../../../types/dataframes/options-builder-item-types';
import { KeyMeasure, TKeyMeasure } from '../../../api/analytics/KeyMeasure';
import { Dimension, TDimension } from '../../../api/analytics/Dimension';
import { TUnitType, UnitType } from '../../../api/analytics/UnitType';
import { Period, TPeriod } from '../../../api/analytics/Period';
import { Folder, TFolder } from '../../../api/foundational-elements/Folder';
import { FolderTypesEnum } from '../../../enums/folder-types-enum';
import { AlertVariant, Card, CardBody } from '@patternfly/react-core';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
	DataframeDataRetrievalRequest,
	DataframeDataRetrievalResponse,
	TDataframe,
	TDataframeOrder,
	TDateRange,
	TReport,
} from '../../../api/types';
import { Dataframe, TNewDataframe, TNewDataframeOrder } from '../../../api/dataframes/Dataframes';
import { Report } from '../../../api/reports/Reports';
import Loader from '../../../components/util/Loader';
import { isEqual } from 'lodash';
import { DataBuilderPropsV2, DraggableMenuItemData } from '../../../types/databuilder/databuilder';
import { TNewDateRange } from '../../../api/types/TNewDateRange';
import ZiDataBuilderV2 from '../../../components/data-builder/ZiDataBuilderV2';
import { DataBuilderTypes } from '../../../enums/data-builder-types';
import './BuildReport.scss';
import {
	buildDataframeRequest,
	populateDroppedColumnsByDataframe,
	populateDroppedFactsByDataframe,
	populateDroppedFactsByKeyMeasure,
	populateDroppedFactsByKeyMeasureFact,
	populateDroppedFiltersByDataframe,
	populateDroppedRowsByDataframe,
	populateDroppedRowsByKeyMeasure,
	populateDroppedRowsByKeyMeasureFact,
} from '../../../hooks/DataBuilderHooks';
import {
	useApplication,
	useIsGranted,
	useUser,
} from '../../../components/user/ApplicationProvider';
import { MultipartResponse } from '../../../helpers/multipart-response.helper';
import Preview, { IPreviewProps } from '../../../components/data-builder/Preview';
import { ReportConditionalFormattingRuleEnum } from '../../../enums/report-condition-formatting-rule';
import { selectedPeriod } from '../../../helpers/selected-period.helper';
import { DateRange } from '../../../api/date-period-selector/DateRange';
import { addNewRecentReport } from '../../../helpers/helper-components/recents-factory-helper';
import { TSharedEntity } from '../../../api/shared-entity/SharedEntity';
import { Permission } from '../../../enums/permission.enum';
import ViewReport from './ViewReport';
import { defaultDateRangeDim } from '../../../constants/default-date-range-dim.constant';
import { Favorites, TFavorites } from '../../../api/favorites/Favorites';
import { FavoriteTypes } from '../../../enums/favorite-types';

const BuildReport = () => {
	const { reportId } = useParams();
	const navigator = useNavigate();
	const isGranted = useIsGranted();
	const hasViewReportPerm = isGranted(Permission.ViewReport);
	const hasCreateReportPerm = isGranted(Permission.CreateReport);
	const hasEditReportPerm = isGranted(Permission.EditReport);
	const { currentDatePeriods } = useApplication();
	const currentPeriod = selectedPeriod();
	const defaultPeriod =
		currentDatePeriods.find((dp) => dp.period === 3) ?? (DateRange.Default() as TDateRange);
	const [searchParams, _] = useSearchParams();
	const queryParams = {
		reportId: searchParams.get('reportId'),
		dataframeId: searchParams.get('dataframeId'),
		measureId: searchParams.get('measureId'),
		measureFactId: searchParams.get('measureFactId'),
		dimensionAttributeId: searchParams.get('dimensionAttributeId'),
		dimensionId: searchParams.get('dimensionId'),
	};
	const { setSubSide } = useOutletContext<OutletContext>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { addToast } = useToast();
	const [measures, setMeasures] = useState<TKeyMeasure[]>([]);
	const [dimensions, setDimensions] = useState<TDimension[]>([]);
	const [unitTypes, setUnitTypes] = useState<TUnitType[]>([]);
	const [periods, setPeriods] = useState<TPeriod[]>([]);
	const [folders, setFolders] = useState<TFolder[]>([]);
	const [dataframe, setDataframe] = useState<TDataframe>();
	const [parentDataframe, setParentDataframe] = useState<TDataframe>();
	const [report, setReport] = useState<TReport>();
	const [name, setName] = useState<string>();
	const [folder, setFolder] = useState<TFolder>();
	const [selectedDate, setSelectedDate] = useState<TNewDateRange | undefined>({
		begin_date: currentPeriod.startPeriod
			? currentPeriod.startPeriod.begin_date
			: defaultPeriod.begin_date ?? 0,
		end_date: currentPeriod.endPeriod
			? currentPeriod.endPeriod.end_date
			: defaultPeriod.end_date ?? 0,
		period: currentPeriod.startPeriod
			? currentPeriod.startPeriod.period
			: defaultPeriod.period ?? 0,
		sequence: currentPeriod.startPeriod
			? currentPeriod.startPeriod.period
			: defaultPeriod.sequence ?? 0,
	});
	const [facts, setFacts] = useState<DraggableMenuItemData[]>([]);
	const [rows, setRows] = useState<DraggableMenuItemData[]>([]);
	const [columns, setColumns] = useState<DraggableMenuItemData[]>([]);
	const [filters, setFilters] = useState<DraggableMenuItemData[]>([]);
	const [parameters, setParameters] = useState<DraggableMenuItemData[]>([]);
	const [order, setOrder] = useState<TDataframeOrder | TNewDataframeOrder | undefined>();
	const [invalidFilters, setInvalidFilters] = useState<DraggableMenuItemData[]>([]);
	const [previewData, setPreviewData] =
		useState<MultipartResponse<DataframeDataRetrievalResponse>>();
	const [isPreviewLoading, setPreviewIsLoading] = useState<boolean>(false);
	const [showPreview, setShowPreview] = useState<boolean>(false);
	const [disablePreviewBtn, setDisablePreviewBtn] = useState<boolean>(true);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [isView, setIsView] = useState<boolean>(false);
	const [isCreate, setIsCreate] = useState<boolean>(false);
	const [dataframeChanged, setDataframeChanged] = useState<boolean>(false);
	const [overrideRequest, setOverrideRequest] = useState<TDataframe | TNewDataframe>();
	const [favorite, setFavorite] = useState<TFavorites>();
	const currentUser = useUser();

	// validation
	const [validated, setValidated] = useState<
		'default' | 'warning' | 'success' | 'error' | undefined
	>();

	useEffect(() => {
		setIsLoading(true);
		setSubSide({ isLoading: true });

		if (reportId) {
			if (hasEditReportPerm) {
				setIsEdit(true);
			} else if (hasViewReportPerm) {
				setIsView(true);
				setSubSide({ hideLeftSideBar: true });
			}

			setValidated('success');
			addNewRecentReport(reportId);
		} else {
			if (hasCreateReportPerm) {
				setIsCreate(true);
			}
			setValidated('default');
		}

		getPageData();
	}, []);

	// Populate dropped items from dataframe
	useEffect(() => {
		if (dataframe) {
			populateDroppedItems(measures, dimensions, unitTypes);
			setOverrideRequest(
				buildDataframeRequest(
					facts,
					rows,
					columns,
					filters,
					'override',
					order,
					false,
					true,
					dataframe,
					folder
				)
			);
		}
	}, [dataframe]);

	useEffect(() => {
		const handler = setTimeout(() => {
			if (!disablePreviewBtn) {
				onPreview();
			}
		}, 500);

		return () => {
			clearTimeout(handler);
		};
	}, [facts, rows, columns, filters, selectedDate, order]);

	const getPageData = () => {
		Promise.all([
			KeyMeasure.GetAll(['keyMeasureFacts']),
			Dimension.GetAll(['dimensionAttributes', 'keyMeasures']),
			Folder.GetAll(),
			UnitType.GetAll(),
			Period.GetAll(),
		])
			.then(([km, dim, folder, ut, p]) => {
				setMeasures(km);
				setDimensions(dim);

				if (folder) {
					const filteredFolders = folder.filter((f) => f.type === FolderTypesEnum.Report);
					setFolders(filteredFolders);
				} else {
					setFolders([
						{
							id: -1,
							name: 'No folders found',
							type: 'dataframes',
							items: [],
						},
					]);
				}

				setUnitTypes(ut);
				setPeriods(p);

				if (queryParams.reportId || reportId) {
					getReportData(+(reportId ?? queryParams.reportId ?? 0));
					getFavoriteData();
				} else if (queryParams.dataframeId) {
					getDataframeData();
				} else if (
					queryParams.measureId &&
					queryParams.measureFactId &&
					(queryParams.dimensionAttributeId || queryParams.dimensionId)
				) {
					populateDroppedItems(km, dim, ut);
					createParentAndChildDataframes(km, dim, ut);
				}
			})
			.catch(() => {
				addToast('There was an issue retrieving data', AlertVariant.danger);
			});
	};

	const getReportData = (reportId: number) => {
		Report.Get(reportId, ['sharedReport'])
			.then((response) => {
				setReport(response);
				setName(response.name);
				getDataframeData(response.dataframe);
			})
			.catch(() => {
				addToast('There was an issue retrieving the report', AlertVariant.danger);
				setIsLoading(false);
				setSubSide({ isLoading: false });
			});
	};

	const getFavoriteData = () => {
		Favorites.Get(+(reportId ?? queryParams.reportId ?? 0), currentUser.id)
			.then((response: TFavorites) => {
				if (response) {
					setFavorite(response);
				} else {
					setFavorite({
						user: currentUser.id,
						object_id: +(reportId ?? queryParams.reportId ?? 0),
						type: FavoriteTypes.report,
						name: report?.name ?? '',
					});
				}
			})
			.catch(() => {
				addToast(
					'There was an issue setting the report as a favorite',
					AlertVariant.danger
				);
			});
	};

	const createParentAndChildDataframes = (
		measures: TKeyMeasure[],
		dimensions: TDimension[],
		unitTypes: TUnitType[]
	) => {
		if (
			queryParams.measureId &&
			queryParams.measureFactId &&
			(queryParams.dimensionAttributeId || queryParams.dimensionId)
		) {
			const childFacts = populateDroppedFactsByKeyMeasure(
				+queryParams.measureId,
				+queryParams.measureFactId,
				measures,
				unitTypes
			);

			const parentFacts = populateDroppedFactsByKeyMeasureFact(
				+queryParams.measureFactId,
				measures,
				unitTypes
			);

			const childRows = populateDroppedRowsByKeyMeasure(
				dimensions,
				queryParams.dimensionId ? +queryParams.dimensionId : undefined,
				queryParams.dimensionAttributeId ? +queryParams.dimensionAttributeId : undefined
			);

			const parentRows = populateDroppedRowsByKeyMeasureFact(
				+queryParams.measureFactId,
				measures,
				dimensions
			);

			const childDataframeRequest: TNewDataframe | TDataframe = buildDataframeRequest(
				childFacts,
				childRows,
				[],
				[],
				'',
				undefined,
				false,
				false,
				undefined,
				undefined
			);

			const parentDataframeRequest: TNewDataframe | TDataframe = buildDataframeRequest(
				parentFacts,
				parentRows,
				[],
				[],
				'',
				undefined,
				false,
				false,
				undefined,
				undefined
			);

			Dataframe.NewDataframe(parentDataframeRequest as TNewDataframe)
				.then((parent: TDataframe) => {
					childDataframeRequest.parent = parent.id;
					Dataframe.NewDataframe(childDataframeRequest as TNewDataframe)
						.then((child: TDataframe) => {
							getDataframeData(child.id);
						})
						.catch((): void => {
							addToast('Error creating child dataframe.', AlertVariant.danger);
							setIsLoading(false);
							setSubSide({ isLoading: false });
						});
				})
				.catch((): void => {
					addToast('Error creating parent dataframe.', AlertVariant.danger);
					setIsLoading(false);
					setSubSide({ isLoading: false });
				});
		}
	};

	const getDataframeData = (dataframeId?: number) => {
		const id = dataframeId ?? queryParams.dataframeId;
		if (id) {
			Dataframe.Get(+id, ['datasets', 'filters', 'rowEntry', 'columnEntry', 'order'])
				.then((response) => {
					if (response.parent) {
						Dataframe.Get(response.parent, [
							'datasets',
							'filters',
							'rowEntry',
							'columnEntry',
							'order',
						])
							.then((parentResponse) => {
								setParentDataframe(parentResponse);
								setDataframe(response);
								setOrder(response.order ? response.order[0] : undefined);
								setDisablePreviewBtn(false);
							})
							.catch(() => {
								addToast(
									'There was an issue retrieving the parent dataframe',
									AlertVariant.danger
								);
								setIsLoading(false);
								setSubSide({ isLoading: false });
							});
					} else {
						setDataframe(response);
						setOrder(response.order ? response.order[0] : undefined);
						setDisablePreviewBtn(false);
					}
				})
				.catch(() => {
					addToast('There was an issue retrieving the dataframe', AlertVariant.danger);
					setIsLoading(false);
					setSubSide({ isLoading: false });
				});
		}
	};

	const saveDataframe = () => {
		const filterValidations = filters.filter(
			(filter) =>
				filter.entityType != OptionsBuilderItemTypes.DatePeriodSelector &&
				((filter.data?.isExistingValue && !filter.data?.value) ||
					(!filter.data?.isExistingValue && !filter.data?.operator))
		);

		if (filterValidations.length > 0) {
			setInvalidFilters(filterValidations);

			addToast(
				'Please ensure that values have been set for all Dataframe filters.',
				AlertVariant.danger
			);
			return;
		}

		const request = buildDataframeRequest(
			facts,
			rows,
			columns,
			filters,
			name ? name : '',
			order,
			(queryParams.reportId != void 0 || queryParams.dataframeId != void 0) &&
				!dataframe?.parent,
			queryParams.dataframeId ? false : true,
			dataframe,
			folder
		);

		setIsLoading(true);

		if (queryParams.dataframeId) {
			Dataframe.NewDataframe(request as TNewDataframe)
				.then((_: TDataframe): void => {
					setDisablePreviewBtn(false);
					getDataframeData(_.id);
					saveReport(_.id);
				})
				.catch((): void => {
					addToast('Error creating dataframe.', AlertVariant.danger);
					setIsLoading(false);
				});
		} else {
			Dataframe.PatchDataframe(request as TNewDataframe)
				.then((_: TDataframe): void => {
					getDataframeData(_.id);
					saveReport(_.id);
				})
				.catch((): void => {
					addToast('Error updating dataframe.', AlertVariant.danger);
					setIsLoading(false);
				});
		}
	};

	const saveReport = (dataframeId: number) => {
		const request = {
			...(report && { id: report.id }),
			name: name ? name : '',
			description: '',
			folder: folder ? folder?.id : 0,
			dataframe: dataframeId,
			conditionalRules: {
				id: 0,
				report: 0,
				start_range: 0,
				end_range: 0,
				$rule: ReportConditionalFormattingRuleEnum.BETWEEN,
				value: 0,
				value2: 0,
				background_color: '',
				color: '',
			},
			owner: currentUser.id,
			sharedReport: report?.sharedReport ?? [],
			is_from_dataframe: report?.is_from_dataframe ?? queryParams.dataframeId ? true : false,
		};

		if (isEdit) {
			Report.Patch(request)
				.then((_: TReport): void => {
					getReportData(_.id);
				})
				.catch((): void => {
					addToast('Error updating report.', AlertVariant.danger);
					setIsLoading(false);
				});
		} else {
			Report.New(request)
				.then((_: TReport): void => {
					setIsLoading(false);
					setIsEdit(true);
					navigator(`/report/edit/${_.id}`);
				})
				.catch((): void => {
					addToast('Error creating report.', AlertVariant.danger);
					setIsLoading(false);
				});
		}
	};

	const populateDroppedItems = (
		measures: TKeyMeasure[],
		dimensions: TDimension[],
		unitTypes: TUnitType[]
	) => {
		const dateRangeSelector: DraggableMenuItemData = {
			id: '-1',
			entityType: OptionsBuilderItemTypes.DatePeriodSelector,
			allowedZones: [],
			data: null,
			entity: {},
			itemType: 'date',
			static: true,
		};

		if (dataframe) {
			setFacts(populateDroppedFactsByDataframe(dataframe, measures));
			setRows(populateDroppedRowsByDataframe(dataframe, dimensions));
			setColumns(populateDroppedColumnsByDataframe(dataframe, dimensions));
			// Ensure to add the date range selector by default
			setFilters([
				...[dateRangeSelector],
				...populateDroppedFiltersByDataframe(dataframe, dimensions),
			]);
		} else if (
			queryParams.measureId &&
			queryParams.measureFactId &&
			(queryParams.dimensionAttributeId || queryParams.dimensionId)
		) {
			setFacts(
				populateDroppedFactsByKeyMeasure(
					+queryParams.measureId,
					+queryParams.measureFactId,
					measures,
					unitTypes
				)
			);
			setRows(
				populateDroppedRowsByKeyMeasure(
					dimensions,
					queryParams.dimensionId ? +queryParams.dimensionId : undefined,
					queryParams.dimensionAttributeId ? +queryParams.dimensionAttributeId : undefined
				)
			);
		}
	};

	const onSave = (): void => {
		if (dataframeChanged) {
			saveDataframe();
		} else {
			saveReport(dataframe?.id ?? 0);
		}
	};

	const onPreview = (): void => {
		if (dataframe) {
			setPreviewIsLoading(true);
			setShowPreview(true);

			const newOverrideRequest = buildDataframeRequest(
				facts,
				rows,
				columns,
				[...filters, ...parameters.filter((param) => param.data?.operator)],
				'override',
				order,
				false,
				true,
				dataframe,
				folder
			);

			const request: DataframeDataRetrievalRequest = {
				dataframeId: dataframe ? dataframe.id : 0,
				begin_date: selectedDate?.begin_date ?? 0,
				end_date: selectedDate?.end_date ?? 0,
				periodId: selectedDate?.period ?? 0,
				...(!isEqual(overrideRequest, newOverrideRequest) && {
					override: newOverrideRequest,
				}),
			};

			Dataframe.RetrieveReport(request)
				.then((response: MultipartResponse<DataframeDataRetrievalResponse>): void => {
					setPreviewData(response);
					setPreviewIsLoading(false);
				})
				.catch((): void => {
					addToast('Error fetching preview data.', AlertVariant.danger);
					setPreviewIsLoading(false);
				})
				.finally(() => {
					setIsLoading(false);
					setSubSide({ isLoading: false });
				});
		}
	};

	const getReportSharedPermissions = () => {
		let userSharedRecord: { canEdit: boolean; canShare: boolean } = {
			canEdit: false,
			canShare: false,
		};
		if (!report || currentUser.id === report?.owner) {
			userSharedRecord = {
				canEdit: true,
				canShare: true,
			};
		} else {
			report?.sharedReport?.map((sc) => {
				const thisUser = (sc as TSharedEntity).shared_entity_users?.find(
					(u) => u.user === currentUser.id
				);
				const thisGroup = (sc as TSharedEntity).shared_entity_groups?.find(
					(u) => currentUser.groups.findIndex((ug) => ug === u.group) > -1
				);
				if (thisUser) {
					userSharedRecord = { canEdit: thisUser.can_edit, canShare: thisUser.can_share };
				} else if (thisGroup) {
					userSharedRecord = {
						canEdit: thisGroup.can_edit,
						canShare: thisGroup.can_share,
					};
				}
			});
		}

		return userSharedRecord;
	};

	const builderProps: DataBuilderPropsV2 = {
		dataframe,
		parentDataframe,
		formLabel: 'Report Name',
		onSave,
		onPreview,
		isEdit,
		measures,
		dimensions,
		unitTypes,
		periods,
		folders,
		selectedDate,
		setSelectedDate,
		facts,
		rows,
		columns,
		filters,
		invalidFilters,
		setFacts,
		setRows,
		setColumns,
		setFilters,
		name: name ?? '',
		setName: setName,
		folder,
		setFolder,
		sidebarHeader: 'Add Data',
		sidebarSubheader: 'Drag & drop data to add it to your report',
		validated,
		setValidated,
		type: DataBuilderTypes.report,
		disablePreviewBtn,
		setHasChanges: setDataframeChanged,
		showLimitWarning: previewData?.json?.result_size?.limitReached,
		limitDimensionOptions: queryParams.dataframeId ? true : report?.is_from_dataframe,
		sharedPermissions: getReportSharedPermissions(),
		previewData,
		favorite: favorite,
		setFavorite: getFavoriteData,
	};

	const previewProps: IPreviewProps = {
		data: previewData,
		facts,
		rows,
		columns,
		unitTypes,
		isLoading: isPreviewLoading,
		setIsLoading: setPreviewIsLoading,
		order: order ?? dataframe?.order?.[0] ?? undefined,
		setOrder,
	};

	const getBuilderTemplate = () => {
		if (isEdit || isCreate) {
			return <>{isLoading ? <Loader /> : <ZiDataBuilderV2 {...builderProps} />}</>;
		} else if (isView) {
			return (
				<>
					{isLoading ? (
						<Loader />
					) : (
						<ViewReport
							dimensions={[...dimensions, ...[defaultDateRangeDim]]}
							folder={folder}
							folders={folders}
							name={name}
							onRunReport={onPreview}
							parameters={parameters}
							setParameters={setParameters}
							previewData={previewData}
							setSelectedDate={setSelectedDate}
							dataframe={parentDataframe! ?? dataframe!}
						/>
					)}
				</>
			);
		}
	};

	return (
		<Card className="report-container">
			<CardBody>
				{getBuilderTemplate()}
				{showPreview && !isLoading && <Preview {...previewProps} />}
			</CardBody>
		</Card>
	);
};

export default BuildReport;
