import React, { ReactElement, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../layout/subheader/PageTitleSubheader';
import { OutletContext } from '../layout/Layout';
import { Card, CardBody, Tabs, Tab, Text, Label } from '@patternfly/react-core';
import MainSubNavContext from '../helpers/sub-nav-contexts/main-sub-nav-context';
import ListCards from '../components/card/ListCards';
import AlertCardsHomePage from '../helpers/helper-components/alert-cards-home-page';
import { CardProps } from '../types/cards/card-props';
import {
	faTable,
	faDisplayChartUpCircleCurrency,
	faNewspaper,
	faPersonChalkboard,
	faChartSimpleHorizontal,
} from '@fortawesome/pro-light-svg-icons';
import { Recent } from '../api/recents/recents';
import { CardTypesEnum } from '../enums/card-types.enum';
import { RecentTypesEnum } from '../enums/recent-types.enum';
import { useMount } from 'react-use';
import { sortByTimestamp } from '../helpers/helper-components/recents-factory-helper';
import { ChartIconMap } from '../helpers/helper-components/chart-helper';
import { ChartTypesEnum } from '../enums/chart-types.enum';
import { useIsGranted, useUser } from '../components/user/ApplicationProvider';
import { Permission } from '../enums/permission.enum';
import { SubNavContext } from '../helpers/sub-nav-contexts/sub-nav-context';
import { TUser } from '../api/security/User';
import { Favorites, TFavorites } from '../api/favorites/Favorites';
import { FavoriteTypes } from '../enums/favorite-types';

function GettingStarted(): ReactElement {
	const { setSubSide, subNavExpanded, setSubNavExpanded } = useOutletContext<OutletContext>();
	const [activeTabKey, setActiveTabKey] = useState(-1);
	const [nestedActiveTabKey, setNestedActiveTabKey] = useState(0);
	const [recentCardsDataframes, setRecentCardsDataframes] = useState<CardProps[]>();
	const [recentCardsCharts, setRecentCardsCharts] = useState<CardProps[]>();
	const [recentCardsDashboards, setRecentCardsDashboards] = useState<CardProps[]>();
	const [recentCardsReports, setRecentCardsReports] = useState<CardProps[]>();
	const [recentCardsPresents, setRecentCardsPresents] = useState<CardProps[]>();
	const [recentCardsAll, setRecentCardsAll] = useState<CardProps[]>();
	const [favoriteCardsDataframes, setFavoriteCardsDataframes] = useState<CardProps[]>();
	const [favoriteCardsCharts, setFavoriteCardsCharts] = useState<CardProps[]>();
	const [favoriteCardsDashboards, setFavoriteCardsDashboards] = useState<CardProps[]>();
	const [favoriteCardsReports, setFavoriteCardsReports] = useState<CardProps[]>();
	const [favoriteCardsAll, setFavoritesCardsAll] = useState<CardProps[]>();
	const isGranted = useIsGranted();
	const hasViewFavouritePerm = isGranted(Permission.ViewFavorite);
	const hasViewRecentPerm = isGranted(Permission.ViewRecent);
	const hasViewChartPerm = isGranted(Permission.ViewChart);
	const hasViewDataframePerm = isGranted(Permission.ViewDataframe);
	const hasViewDashboardPerm = isGranted(Permission.ViewDashboard);
	const hasViewReportPerm = isGranted(Permission.ViewReport);
	const hasEditChartPerm = isGranted(Permission.EditChart);
	const hasEditDataframePerm = isGranted(Permission.EditDataframe);
	const hasEditDashboardPerm = isGranted(Permission.EditDashboard);
	const hasEditReportPerm = isGranted(Permission.EditReport);
	const [subNavContext, setSubNavContext] = useState<SubNavContext>(MainSubNavContext);
	const currentUser = useUser();

	useMount(() => {
		const prevSelectedParentTabIdx = localStorage.getItem('hp_selected_parent_tabIdx');
		const prevSelectedChildTabIdx = localStorage.getItem('hp_selected_child_tabIdx');

		if (prevSelectedParentTabIdx) {
			setActiveTabKey(Number(prevSelectedParentTabIdx));
			if (prevSelectedParentTabIdx == '-1' && prevSelectedChildTabIdx) {
				setNestedActiveTabKey(Number(prevSelectedChildTabIdx));
			}
		}
	});

	// Define a function to handle tab selection
	const handleTabSelect = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		tabIndex: string | number
	): void => {
		setActiveTabKey(Number(tabIndex));
		localStorage.setItem('hp_selected_parent_tabIdx', tabIndex.toString());

		if (tabIndex == -1) {
			localStorage.removeItem('hp_selected_child_tabIdx');
		}
	};
	const handleNestedTabSelect = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		tabIndex: string | number
	): void => {
		setNestedActiveTabKey(Number(tabIndex));
		localStorage.setItem('hp_selected_child_tabIdx', tabIndex.toString());
	};

	useEffect(() => {
		setSubSide({
			subheaderContext: (
				<PageTitleSubheader
					pageTitle=""
					pageDescription=""
					expanded={subNavExpanded}
					setExpanded={setSubNavExpanded}
					isCollapsable
					subNavContext={subNavContext}
				/>
			),
			sidebarContext: null,
			additionalClassNames: 'home-page-subbar no-title',
			hideLeftSideBar: true,
		});
	}, [setSubSide, subNavExpanded, setSubNavExpanded, subNavContext]);

	useMount(() => {
		if (!hasViewRecentPerm) {
			return;
		}

		GetAllRecents();
		GetAllFavorites();
	});

	const clusteredChartTypes = ['clusteredcolumn', 'clusteredbar'];

	function GetAllRecents() {
		const promises = [
			Recent.GetAll(RecentTypesEnum.Dataframe),
			Recent.GetAll(RecentTypesEnum.Charts),
			Recent.GetAll(RecentTypesEnum.Dashboard),
			Recent.GetAll(RecentTypesEnum.Report),
			Recent.GetAll(RecentTypesEnum.Presentation),
		];

		// Use Promise.all to wait for all promises to complete
		Promise.all(promises)
			.then(([dataframes, charts, dashboards, reports, presentations]) => {
				//process dataframes
				const recentsDataframesCards = dataframes.map((item) => ({
					icon: faTable,
					type: CardTypesEnum.DataFrame,
					url: `/analyze/${CardTypesEnum.DataFrame}/edit/${item.entity_id}`,
					title: item.name ?? '',
					isShared: (item.owner as TUser).id !== currentUser.id,
					ownerName: (item.owner as TUser).name,
					isRecent: true,
					timestamp: item.accessed_at,
					isClustered: false,
					id: item.entity_id,
				}));

				//process charts
				const recentsChartCards = charts.map((item) => ({
					icon: ChartIconMap[item.chart_type as ChartTypesEnum],
					type: CardTypesEnum.Chart,
					url: `/analyze/${CardTypesEnum.Chart}/build/chart/${item.entity_id}`,
					title: item.name ?? '',
					isShared: (item.owner as TUser).id !== currentUser.id,
					ownerName: (item.owner as TUser).name,
					isRecent: true,
					timestamp: item.accessed_at,
					isClustered: item.chart_type
						? clusteredChartTypes.includes(item.chart_type)
						: false,
					id: item.entity_id,
				}));

				setSubNavContext({
					...subNavContext,
					reportSnapshot: {
						title: `From Your Recents: ${charts.length ? charts[0].name ?? '' : ''}`,
						snapshotText: '',
						...(charts.length && { chartId: charts[0].entity_id }),
					},
				});

				//process dashboards
				const recentsDashboardCards = dashboards.map((item) => ({
					icon: faDisplayChartUpCircleCurrency,
					type: CardTypesEnum.Dashboard,
					url: `/analyze/${CardTypesEnum.Dashboard}/view/${item.entity_id}`,
					title: item.name ?? '',
					isShared: (item.owner as TUser).id !== currentUser.id,
					ownerName: (item.owner as TUser).name,
					isRecent: true,
					timestamp: item.accessed_at,
					isClustered: false,
					id: item.entity_id,
				}));

				//process reports
				const recentsReportCards = reports.map((item) => ({
					icon: faNewspaper,
					type: CardTypesEnum.Report,
					url: hasEditReportPerm
						? `/report/edit/${item.entity_id}`
						: hasViewReportPerm
						? `/report/view/${item.entity_id}`
						: '',
					title: item.name ?? '',
					isShared: (item.owner as TUser).id !== currentUser.id,
					ownerName: (item.owner as TUser).name,
					isRecent: true,
					timestamp: item.accessed_at,
					isClustered: false,
					id: item.entity_id,
				}));

				//process presentations
				const recentsPresentationCards = presentations.map((item) => ({
					icon: faPersonChalkboard,
					type: CardTypesEnum.Presentation,
					url: `/analyze/${CardTypesEnum.Presentation}/${item.entity_id}`,
					title: item.name ?? '',
					isShared: (item.owner as TUser).id !== currentUser.id,
					ownerName: (item.owner as TUser).name,
					isRecent: true,
					timestamp: item.accessed_at,
					isClustered: false,
					id: item.entity_id,
				}));

				//sorting
				const sortedDataframeRecents = sortByTimestamp(recentsDataframesCards);
				const sortedChartRecents = sortByTimestamp(recentsChartCards);
				const sortedDashboardRecents = sortByTimestamp(recentsDashboardCards);
				const sortedReportRecents = sortByTimestamp(recentsReportCards);
				const sortedPresentRecents = sortByTimestamp(recentsPresentationCards);
				setRecentCardsDataframes(sortedDataframeRecents);
				setRecentCardsCharts(sortedChartRecents);
				setRecentCardsDashboards(sortedDashboardRecents);
				setRecentCardsReports(sortedReportRecents);
				setRecentCardsPresents(sortedPresentRecents);

				const combinedRecents = [
					...recentsDataframesCards,
					...recentsChartCards,
					...recentsDashboardCards,
					...recentsReportCards,
					...recentsPresentationCards,
				];
				const sortedCombinedRecents = sortByTimestamp(combinedRecents).slice(0, 18);
				setRecentCardsAll(sortedCombinedRecents);
			})
			.catch((error) => {
				// Handle errors
				console.error('Error fetching recent data:', error);
			});
	}

	function GetAllFavorites() {
		const promises = [
			Favorites.GetByType(currentUser.id, FavoriteTypes.dataframe),
			Favorites.GetByType(currentUser.id, FavoriteTypes.chart),
			Favorites.GetByType(currentUser.id, FavoriteTypes.report),
			Favorites.GetByType(currentUser.id, FavoriteTypes.dashboard),
		];

		// Use Promise.all to wait for all promises to complete
		Promise.all(promises)
			.then(([dataframes, charts, reports, dashboards]) => {
				//process dataframes
				const favoritesDataframesCards = dataframes.map((item: TFavorites) => ({
					icon: faTable,
					type: CardTypesEnum.DataFrame,
					url:
						hasEditDataframePerm || hasViewDataframePerm
							? `/analyze/${CardTypesEnum.DataFrame}/edit/${item.object_id ?? 0}`
							: '',
					title: item.name ?? '',
					isRecent: true,
					isClustered: false,
					id: item.object_id,
				}));

				//process charts
				const favoritesChartCards = charts.map((item: TFavorites) => ({
					icon: item.chartType
						? ChartIconMap[item.chartType as ChartTypesEnum]
						: faChartSimpleHorizontal,
					type: CardTypesEnum.Chart,
					url:
						hasEditChartPerm || hasViewChartPerm
							? `/analyze/${CardTypesEnum.Chart}/build/chart/${item.object_id ?? 0}`
							: '',
					title: item.name ?? '',
					isRecent: true,
					id: item.object_id,
				}));

				setSubNavContext({
					...subNavContext,
					reportSnapshot: {
						title: `From Your Favorites: ${charts.length ? charts[0].name ?? '' : ''}`,
						snapshotText: '',
						...(charts.length && { chartId: charts[0].object_id }),
					},
				});

				//process dashboards
				const favoritesDashboardCards = dashboards.map((item: TFavorites) => ({
					icon: faDisplayChartUpCircleCurrency,
					type: CardTypesEnum.Dashboard,
					url:
						hasEditDashboardPerm || hasViewDashboardPerm
							? `/analyze/${CardTypesEnum.Dashboard}/view/${item.object_id ?? 0}`
							: '',
					title: item.name ?? '',
					isRecent: true,
					isClustered: false,
					id: item.object_id,
				}));

				//process reports
				const favoritesReportCards = reports.map((item: TFavorites) => ({
					icon: faNewspaper,
					type: CardTypesEnum.Report,
					url: hasEditReportPerm
						? `/report/edit/${item.object_id ?? 0}`
						: hasViewReportPerm
						? `/report/view/${item.object_id ?? 0}`
						: '',
					title: item.name ?? '',
					isRecent: true,
					isClustered: false,
					id: item.object_id,
				}));

				//sorting
				const sortedDataframeFavorites = sortByTimestamp(favoritesDataframesCards);
				const sortedChartFavorites = sortByTimestamp(favoritesChartCards);
				const sortedDashboardFavorites = sortByTimestamp(favoritesDashboardCards);
				const sortedReportFavorites = sortByTimestamp(favoritesReportCards);
				setFavoriteCardsDataframes(sortedDataframeFavorites);
				setFavoriteCardsCharts(sortedChartFavorites);
				setFavoriteCardsDashboards(sortedDashboardFavorites);
				setFavoriteCardsReports(sortedReportFavorites);

				const combinedFavorites = [
					...favoritesDataframesCards,
					...favoritesChartCards,
					...favoritesDashboardCards,
					...favoritesReportCards,
				];
				const sortedCombinedFavorites = sortByTimestamp(combinedFavorites).slice(0, 18);
				setFavoritesCardsAll(sortedCombinedFavorites);
			})
			.catch((error) => {
				// Handle errors
				console.error('Error fetching favorites data:', error);
			});
	}

	return (
		<React.Fragment>
			<Card className="recents-card-panel">
				<CardBody>
					<Tabs
						className="parent-tabs"
						activeKey={activeTabKey}
						onSelect={handleTabSelect}
					>
						{hasViewRecentPerm && (
							<Tab
								eventKey={-1}
								title={
									<span
										dangerouslySetInnerHTML={{
											__html: '<strong>Recents<strong/>',
										}}
									></span>
								}
							>
								{/* Content for Tab 1 */}
								<div>
									{' '}
									<Tabs
										activeKey={nestedActiveTabKey}
										onSelect={handleNestedTabSelect}
										className="child-tabs"
									>
										<Tab
											eventKey={0}
											title="All"
										>
											{/* Content for Tab All */}
											{recentCardsAll && (
												<div>
													<ListCards
														additionalClassNames="scrollable-cards"
														items={recentCardsAll}
													/>
												</div>
											)}
											{recentCardsAll && recentCardsAll.length < 1 && (
												<div>
													<Label>No recents were found</Label>
												</div>
											)}
										</Tab>
										<Tab
											eventKey={1}
											title="Dashboards"
										>
											{/* Content for Tab Dashboards */}
											{recentCardsDashboards && (
												<div>
													<ListCards
														additionalClassNames="scrollable-cards"
														items={recentCardsDashboards}
													/>
												</div>
											)}
											{recentCardsDashboards &&
												recentCardsDashboards.length < 1 && (
													<div>
														<Label>
															No recent Dashboards were found
														</Label>
													</div>
												)}
										</Tab>
										<Tab
											eventKey={2}
											title="Charts"
										>
											{/* Content for Tab Charts */}
											{recentCardsCharts && (
												<div>
													<ListCards
														additionalClassNames="scrollable-cards"
														items={recentCardsCharts}
													/>
												</div>
											)}
											{recentCardsCharts && recentCardsCharts.length < 1 && (
												<div>
													<Label>No recent Charts were found</Label>
												</div>
											)}
										</Tab>
										<Tab
											eventKey={3}
											title="Dataframes"
										>
											{/* Content for Tab Dataframes */}
											{recentCardsDataframes && (
												<div>
													<ListCards
														additionalClassNames="scrollable-cards"
														items={recentCardsDataframes}
													/>
												</div>
											)}
											{recentCardsDataframes &&
												recentCardsDataframes.length < 1 && (
													<div>
														<Label>
															No recent Dataframes were found
														</Label>
													</div>
												)}
										</Tab>
										<Tab
											eventKey={4}
											title="Reports"
										>
											{/* Content for Tab Reports */}
											{recentCardsReports && (
												<div>
													<ListCards
														additionalClassNames="scrollable-cards"
														items={recentCardsReports}
													/>
												</div>
											)}
											{recentCardsReports &&
												recentCardsReports.length < 1 && (
													<div>
														<Label>No recent Reports were found</Label>
													</div>
												)}
										</Tab>
										<Tab
											eventKey={5}
											title="Presentations"
										>
											{/* Content for Tab Presentations */}
											{recentCardsPresents && (
												<div>
													<ListCards
														additionalClassNames="scrollable-cards"
														items={recentCardsPresents}
													/>
												</div>
											)}
											{recentCardsPresents &&
												recentCardsPresents.length < 1 && (
													<div>
														<Label>
															No recent Presentations were found
														</Label>
													</div>
												)}
										</Tab>
									</Tabs>
								</div>
							</Tab>
						)}

						{hasViewFavouritePerm && (
							<Tab
								eventKey={-2}
								title={
									<span
										dangerouslySetInnerHTML={{
											__html: '<strong>Favorites<strong/>',
										}}
									></span>
								}
							>
								{/* Content for Tab 2 */}
								<div>
									<Tabs
										activeKey={nestedActiveTabKey}
										onSelect={handleNestedTabSelect}
										className="child-tabs"
									>
										<Tab
											eventKey={0}
											title="All"
										>
											{/* Content for Tab All */}
											{favoriteCardsAll && (
												<div>
													<ListCards
														additionalClassNames="scrollable-cards"
														items={favoriteCardsAll}
													/>
												</div>
											)}
											{favoriteCardsAll && favoriteCardsAll.length < 1 && (
												<div>
													<Label>No favorites were found</Label>
												</div>
											)}
										</Tab>
										<Tab
											eventKey={1}
											title="Dashboards"
										>
											{/* Content for Tab Favorites */}
											{favoriteCardsDashboards && (
												<div>
													<ListCards
														additionalClassNames="scrollable-cards"
														items={favoriteCardsDashboards}
													/>
												</div>
											)}
											{favoriteCardsDashboards &&
												favoriteCardsDashboards.length < 1 && (
													<div>
														<Label>
															No favorite Dashboards were found
														</Label>
													</div>
												)}
										</Tab>
										<Tab
											eventKey={2}
											title="Charts"
										>
											{/* Content for Tab Charts */}
											{favoriteCardsCharts && (
												<div>
													<ListCards
														additionalClassNames="scrollable-cards"
														items={favoriteCardsCharts}
													/>
												</div>
											)}
											{favoriteCardsCharts &&
												favoriteCardsCharts.length < 1 && (
													<div>
														<Label>No favorite Charts were found</Label>
													</div>
												)}
										</Tab>
										<Tab
											eventKey={3}
											title="Dataframes"
										>
											{/* Content for Tab Dataframes */}
											{favoriteCardsDataframes && (
												<div>
													<ListCards
														additionalClassNames="scrollable-cards"
														items={favoriteCardsDataframes}
													/>
												</div>
											)}
											{favoriteCardsDataframes &&
												favoriteCardsDataframes.length < 1 && (
													<div>
														<Label>
															No favorite Dataframes were found
														</Label>
													</div>
												)}
										</Tab>
										<Tab
											eventKey={4}
											title="Reports"
										>
											{/* Content for Tab Reports */}
											{favoriteCardsReports && (
												<div>
													<ListCards
														additionalClassNames="scrollable-cards"
														items={favoriteCardsReports}
													/>
												</div>
											)}
											{favoriteCardsReports &&
												favoriteCardsReports.length < 1 && (
													<div>
														<Label>
															No favorite Reports were found
														</Label>
													</div>
												)}
										</Tab>
									</Tabs>
								</div>
							</Tab>
						)}
					</Tabs>
				</CardBody>
			</Card>
			<br />
			<Card>
				<CardBody>
					<Text className="font-size-md">
						<strong>Alerts</strong>
					</Text>
					<br />
					<AlertCardsHomePage />
				</CardBody>
			</Card>
		</React.Fragment>
	);
}

export default GettingStarted;
