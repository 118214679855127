import {
	faShareNodes,
	faDownToBracket,
	faMessage,
	faStar,
} from '@fortawesome/pro-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertVariant, Button, Flex, FlexItem } from '@patternfly/react-core';
import { useEffect, useState } from 'react';
import ShareEntityModal from './ShareEntityModal';
import { useIsGranted } from '../../components/user/ApplicationProvider';
import { Permission } from '../../enums/permission.enum';
import CsvDownloader from 'react-csv-downloader';
import { MultipartResponse, rebuildCsvStringArray } from '../multipart-response.helper';
import { DataframeDataRetrievalResponse } from '../../api/types';
import { Columns, Datas } from 'react-csv-downloader/dist/esm/lib/csv';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import { dateToYYYYMMDDHHMMSS } from '../../utilities';
import { Favorites, TFavorites } from '../../api/favorites/Favorites';

type Props = {
	entityType: string;
	entityName: string;
	canShare: boolean;
	exportData?: MultipartResponse<DataframeDataRetrievalResponse>;
	limitReached?: boolean;
	favorite?: TFavorites;
	setFavorite?: () => void;
};

function EntityMiscButtons(props: Props) {
	const [isOpenShareModal, setIsOpenShareModal] = useState(false);
	const isGranted = useIsGranted();
	const { addToast } = useToast();
	const [exportColumns, setExportColumns] = useState<Columns>();
	const [exportData, setExportData] = useState<Datas>();
	const [exportFileName, setExportFileName] = useState<string>();

	useEffect(() => {
		if (props.exportData && props.exportData.json && props.exportData.csvData) {
			setExportColumns(
				props.exportData.json.columns.map((col) => {
					return {
						id: col.replace(/\s/g, ''),
						displayName: `"${col}"`,
					};
				})
			);

			setExportData(
				props.exportData.csvData.map((data) => {
					return rebuildCsvStringArray(data, false);
				})
			);

			setExportFileName(`${props.entityName}_${dateToYYYYMMDDHHMMSS(new Date())}`);
		} else {
			setExportColumns(undefined);
			setExportData(undefined);
			setExportFileName(undefined);
		}
	}, [props.exportData]);

	const closeShareModal = () => {
		setIsOpenShareModal(false);
	};

	const openShareModal = () => {
		setIsOpenShareModal(true);
	};

	const exportButtonTemplate = () => {
		const template = (
			<Button
				variant="plain"
				aria-label="Download Entiy"
				onClick={(e) => {
					if (exportData) {
						if (props.limitReached) {
							addToast(
								'More than 500 rows are present in the dataset. Apply filters to narrow your selection and reduce the number of rows.',
								AlertVariant.danger
							);
						}
					} else if (!exportData || !exportColumns) {
						addToast('Please preview data before exporting it.', AlertVariant.danger);
					} else {
						e.preventDefault();
					}
				}}
			>
				<FontAwesomeIcon
					icon={faDownToBracket}
					className="download-icon"
					size="xl"
				/>
			</Button>
		);

		if (exportData && exportColumns && !props.limitReached) {
			return (
				<CsvDownloader
					columns={exportColumns}
					datas={exportData}
					filename={exportFileName ?? ''}
					extension=".csv"
					separator=","
				>
					{template}
				</CsvDownloader>
			);
		}

		return template;
	};

	const toggleFavorite = () => {
		if (props.favorite) {
			Favorites.ToggleFavorite(props.favorite)
				.then(() => {
					if (props.setFavorite) {
						props.setFavorite();
					}
				})
				.catch(() => {
					addToast('An error occured while saving a Favorite', AlertVariant.danger);
				});
		}
	};

	if (!isGranted(Permission.viewSharedEntity)) {
		return null;
	}

	return (
		<div>
			<Flex
				className="lbl-entity-actions"
				flex={{ default: 'flex_1' }}
			>
				<Flex
					spaceItems={{ default: 'spaceItemsNone' }}
					align={{ default: 'alignRight' }}
				>
					{props.favorite && (
						<FlexItem>
							<Button
								variant="plain"
								aria-label="Favorite Entity"
								onClick={() => {
									toggleFavorite();
								}}
							>
								<FontAwesomeIcon
									icon={props.favorite.id ? faStarSolid : faStar}
									className="favorite-icon"
									size="xl"
								/>
							</Button>
						</FlexItem>
					)}
					{props.canShare && (
						<FlexItem>
							<Button
								variant="plain"
								aria-label="Share Entity"
								onClick={() => {
									openShareModal();
								}}
							>
								<FontAwesomeIcon
									icon={faShareNodes}
									className="share-icon"
									size="xl"
								/>
							</Button>
						</FlexItem>
					)}
					<FlexItem>{exportButtonTemplate()}</FlexItem>
					<FlexItem>
						<Button
							variant="plain"
							aria-label="Note Entity"
						>
							<FontAwesomeIcon
								icon={faMessage}
								className="note-icon"
								size="xl"
							/>
						</Button>
					</FlexItem>
				</Flex>
			</Flex>

			<ShareEntityModal
				entityType={props.entityType}
				entityName={props.entityName}
				isOpen={isOpenShareModal}
				onClose={closeShareModal}
			/>
		</div>
	);
}

export default EntityMiscButtons;
