import { useEffect, useState } from 'react';
import { IConfig, OutletContext, SideBarTabContext } from '../../layout/Layout';
import {
	AlertVariant,
	Button,
	Form,
	FormGroup,
	Text,
	TextContent,
	TextInput,
	TextVariants,
	Title,
	TreeViewDataItem,
} from '@patternfly/react-core';
import { OptionsBuilderItemTypes } from '../../types/dataframes/options-builder-item-types';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TreeViewSelect from '../../components/form/Select/TreeViewSelect';
import { BuildTreeViewItem } from '../../helpers/tree-view.helper';
import './ZiDataBuilderV2.scss';
import { TKeyMeasureFact } from '../../api/analytics/KeyMeasureFact';
import { TDimension } from '../../api/analytics/Dimension';
import { TFolder } from '../../api/foundational-elements/Folder';
import { TKeyMeasure } from '../../api/analytics/KeyMeasure';
import _, { uniq } from 'lodash';
import { useOutletContext } from 'react-router-dom';
import { DataBuilderPropsV2, DraggableMenuItemData } from '../../types/databuilder/databuilder';
import { TDimensionAttribute } from '../../api/analytics/DimensionAttribute';
import { DataBuilderTypes } from '../../enums/data-builder-types';
import DraggableMenuItem from '../dnd/shared/DraggableMenuItem';
import { DataBuilderZones } from '../../enums/data-builder-zones';
import { ITabNavigationItem } from '../../layout/PageTabNavigationItems';
import { faChevronsDown, faFolder } from '@fortawesome/pro-solid-svg-icons';
import DataBuilderDropGrid from '../dnd/databuilder/DataBuilderDropGrid';
import { KeyMeasureFactOptions } from '../../types/dataframes/dataframe-key-measure-fact-options';
import { DimensionOptions } from '../../types/dataframes/dataframe-dimension-options';
import { FilterOptions } from '../../types/dataframes/FilterOptions';
import { TNewDateRange } from '../../api/types/TNewDateRange';
import { generateGUID } from '../../helpers/guid.helper';
import {
	populateDataForCalcFact,
	populateDataForCalculatedFact,
	populateDataForDimension,
	populateDataForDimensionAttribute,
	populateDataForFact,
} from '../../hooks/DataBuilderHooks';
import ChangeUnitTypeModal from '../modals/databuilder/ChangeUnitTypeModal';
import FactOptionsModal from '../modals/databuilder/FactOptionsModal';
import FactFilterOptionsModal from '../modals/databuilder/FactFilterOptionsModal';
import DimensionOptionsModal from '../modals/databuilder/DimensionOptionsModal';
import FilterModal from '../modals/databuilder/FilterModal';
import { defaultDateRangeDim } from '../../constants/default-date-range-dim.constant';
import { useParams } from 'react-router';
import EntityMiscButtons from '../../helpers/helper-components/EntityMiscButtons';
import { useIsGranted } from '../../components/user/ApplicationProvider';
import { Permission } from '../../enums/permission.enum';
import { Question, TQuestion } from '../../api/questions/Questions';
import { DataBuilderDropGridTypes } from '../../enums/data-builder-drop-grid-types';
import { faLightbulb, faPencil, faTimes, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import DiscoverAddQuestionModal from '../../helpers/helper-components/DiscoverAddQuestionModal';
import DeleteConfirmationModal from '../../helpers/helper-components/DeleteConfirmationModal';
import Loader from '../util/Loader';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import { useMount } from 'react-use';
import ConfigureCalculationModal from '../../helpers/helper-components/ConfigureCalculationModal';
import { defaultCalculatedFact } from '../../constants/default-calculated-fact.constant';
import { LambdaInsightsResponse, TDataframeData } from '../../api/types';
import LambdaInsights from './LambdaInsights';
import { Lambda } from '../../api/lambda/Lambda';

const ZiDataBuilderV2 = (props: DataBuilderPropsV2) => {
	const [availableMeasures, setAvailableMeasures] = useState<TKeyMeasure[]>([]);
	const [availableDimensions, setAvailableDimensions] = useState<TDimension[]>([]);
	const [dragStarted, setDragStarted] = useState<boolean>(false);
	const { setSubSide } = useOutletContext<OutletContext>();
	const [unitTypeModalOpen, setUnitTypeModalOpen] = useState(false);
	const [factOptionsModalOpen, setFactOptionsModalOpen] = useState(false);
	const [factFilterModalOpen, setFactFilterModalOpen] = useState(false);
	const [dimensionOptionsModalOpen, setDimensionOptionsModalOpen] = useState(false);
	const [filterModalOpen, setFilterModalOpen] = useState(false);
	const [currentItem, setCurrentItem] = useState<DraggableMenuItemData | null>(null);
	const [currentZone, setCurrentZone] = useState<DataBuilderZones | null>(null);
	const [dropZonesToHighlight, setDropZonesToHighlight] = useState<DataBuilderZones[]>([]);
	const { frameId, chartId, reportId } = useParams();
	const [isAddQuestionModalOpen, setIsAddQuestionModalOpen] = useState(false);
	const [isDeleteQuestionModalOpen, setIsDeleteQuestionModalOpen] = useState(false);
	const [fetchedQuestions, setFetchedQuestions] = useState<TQuestion[]>([]);
	const [fetchedEditQuestion, setFetchedEditQuestion] = useState<TQuestion>();
	const [deleteQuestionId, setDeleteQuestionId] = useState(0);
	const isGranted = useIsGranted();
	const hasViewQuestionPerm = isGranted(Permission.ViewQuestion);
	const hasCreateQuestionPerm = isGranted(Permission.CreateQuestion);
	const hasEditQuestionPerm = isGranted(Permission.EditQuestion);
	const hasDeleteQuestionPerm = isGranted(Permission.DeleteQuestion);
	const hasAddOrEditDataframePerm =
		isGranted(Permission.CreateDataframe) || isGranted(Permission.EditDataframe);
	const hasAddChartPerm = isGranted(Permission.CreateChart);
	const hasEditChartPerm = isGranted(Permission.EditChart);
	const hasAddOrEditReportPerm =
		isGranted(Permission.CreateReport) || isGranted(Permission.EditReport);
	const [sideBarConfig, setSidebarConfig] = useState<IConfig | null>(null);
	const [showSaveButton, setShowSaveButton] = useState(true);
	const { addToast } = useToast();
	const [currentCalculatedFactDataset, setCurrentCalculatedFactDataset] =
		useState<TDataframeData>();
	const [hasInvalidJoins, setHasInvalidJoins] = useState(false);
	const [isLoadingKMs, setIsLoadingKMs] = useState<boolean>(true);
	const [minimumOneInvalidJoin, setMinimumOneInvalidJoin] = useState<boolean>(false);
	const [insightsLoading, setInsightsLoading] = useState<boolean>(false);
	const [lambdaInsights, setLambdaInsights] = useState<LambdaInsightsResponse>();

	const SetPreSelectedFolder = () => {
		const folderIdFromLocalStorage = localStorage.getItem('currentSelectedFolderId');
		const folderId = folderIdFromLocalStorage ? parseInt(folderIdFromLocalStorage) : 0;

		if (props.folders.length > 0 && folderId > 0) {
			props.setFolder &&
				props.setFolder(props.folders.find((folder: TFolder) => folder.id === folderId));
			localStorage.removeItem('currentSelectedFolderId');
		}
	};

	useMount(() => {
		switch (props.type) {
			case DataBuilderTypes.dataframe:
				setShowSaveButton(hasAddOrEditDataframePerm);
				break;
			case DataBuilderTypes.chart:
				setShowSaveButton(hasAddChartPerm || hasEditChartPerm);
				break;
			case DataBuilderTypes.report:
				setShowSaveButton(hasAddOrEditReportPerm);
				break;
			default:
				break;
		}
		if (!props.sharedPermissions.canEdit) {
			setShowSaveButton(false);
		}
	});

	useEffect(() => {
		SetPreSelectedFolder();
		initializeAvailableSidebarItems();
	}, []);

	useEffect(() => {
		if (props.invalidFilters && props.invalidFilters.length > 0) {
			props.setFilters(
				props.filters.map((item) => {
					const foundFilter = props.invalidFilters?.find(
						(filter) =>
							item.data?.id === filter.data?.id &&
							item.entityType === filter.entityType
					);

					if (foundFilter && item.data) {
						item.data.isInvalid = true;
					}

					return item;
				})
			);
		}
	}, [props.invalidFilters]);

	// Used to set the data for the series tab once a fact has been dropped when the builder type is for dataframes
	useEffect(() => {
		if (props.type === DataBuilderTypes.dataframe) {
			const measureFactIds = props.facts.map((fact) => {
				return fact.data?.id;
			});
			const measureIds = props.measures.map((measure) => {
				if (measure.keyMeasureFacts.some((fact) => measureFactIds.includes(fact.id))) {
					return measure.id;
				}
			});
			setAvailableDimensions([
				defaultDateRangeDim,
				...props.dimensions.filter((dimension) => {
					return dimension.keyMeasures.some((measure) => {
						return measureIds.includes(measure.id);
					});
				}),
			]);
		}
	}, [props.facts]);

	useEffect(() => {
		if (minimumOneInvalidJoin) {
			setHasInvalidJoins(true);
		} else if (isLoadingKMs == false) {
			setHasInvalidJoins(false);
		}
	}, [isLoadingKMs, props.savedDataframeDataJoins, minimumOneInvalidJoin]);

	useEffect(() => {
		const ctx: SideBarTabContext = {
			tabs: buildTabs(),
			hasSearch: true,
			searchPlaceholder: 'Search for Data',
		};

		const headerContext = {
			header: props.sidebarHeader ?? '',
			subheader: props.sidebarSubheader ?? '',
		};

		setSidebarConfig({
			sidebarTabContext: ctx,
			sidebarHeaderContext: headerContext,
			isLoading: false,
			hideLeftSideBar: false,
			isLeftSidebarCollapsable: props.type == DataBuilderTypes.chart,
			startCollapsed: !showSaveButton,
		});
	}, [availableMeasures, availableDimensions]);

	useEffect(() => {
		if (sideBarConfig) {
			setSubSide({
				...sideBarConfig,
				isLeftSidebarCollapsable: props.type == DataBuilderTypes.chart,
				startCollapsed: !showSaveButton,
			});
		}
	}, [sideBarConfig]);

	useEffect(() => {
		if (props.drillSequenceOpen) {
			setSidebarConfig({ ...sideBarConfig, rightSideBar: drilldownTemplate });
		} else {
			if (sideBarConfig?.rightSideBar) {
				setSidebarConfig({ ...sideBarConfig, rightSideBar: undefined });
			}
		}
	}, [props.drillSequenceOpen, props.drills, dropZonesToHighlight]);

	useEffect(() => {
		if (props.insightsOpen) {
			getDiscoverQuestions(true);
		} else {
			if (sideBarConfig?.rightSideBar) {
				setSidebarConfig({ ...sideBarConfig, rightSideBar: undefined });
			}
		}
	}, [props.insightsOpen]);

	const initializeAvailableSidebarItems = () => {
		if (props.type === DataBuilderTypes.dataframe) {
			setAvailableMeasures([defaultCalculatedFact, ...props.measures]);
			setAvailableDimensions([]);
			if (props.dataframe) {
				setDragStarted(true);
			}
		} else if (props.type === DataBuilderTypes.chart) {
			// Set drag event as started due to existence of selected items
			setDragStarted(true);
			setAvailableMeasuresAndDimensionsByDataframe();
		} else if (props.type === DataBuilderTypes.report) {
			// Set drag event as started due to existence of selected items
			setDragStarted(true);
			setAvailableMeasuresAndDimensionsByDataframe();
		}
	};

	const setAvailableMeasuresAndDimensionsByDataframe = () => {
		const measuresToUse: TKeyMeasure[] = [];
		const dimsToUse: TDimension[] = [defaultDateRangeDim];

		if (props.dataframe) {
			// Populate selected measure with selected facts
			let factIds: (number | undefined)[] = [];
			if (props.parentDataframe) {
				factIds = props.parentDataframe?.datasets.map((fact) => fact.keyMeasureFact);
			} else {
				factIds = props.dataframe?.datasets.map((fact) => fact.keyMeasureFact);
			}

			props.measures.forEach((measure: TKeyMeasure) => {
				const clonedMeasure = _.cloneDeep(measure);
				clonedMeasure.keyMeasureFacts = clonedMeasure.keyMeasureFacts.filter(
					(fact: TKeyMeasureFact) => {
						return factIds && factIds.includes(fact.id);
					}
				);
				if (clonedMeasure.keyMeasureFacts.length > 0) {
					measuresToUse.push(clonedMeasure);
				}
			});

			setAvailableMeasures(measuresToUse);

			if (props.limitDimensionOptions) {
				// Populate selected dimensions and selected dimension attributes based on dataframe row entries, column entries and filters
				let dimensionIds: number[] = [];
				let dimensionAttrIds: number[] = [];

				dimensionIds = [
					...(props.parentDataframe ?? props.dataframe).rowEntry
						.filter((row) => {
							return row.entity_type == OptionsBuilderItemTypes.Dimension;
						})
						.map((dim) => dim.entity_id),
					...(props.parentDataframe ?? props.dataframe).columnEntry
						.filter((col) => {
							return col.entity_type == OptionsBuilderItemTypes.Dimension;
						})
						.map((dim) => dim.entity_id),
					...(props.parentDataframe ?? props.dataframe).filters
						.filter((filter) => {
							return filter.entity_type == OptionsBuilderItemTypes.Dimension;
						})
						.map((dim) => dim.entity_id),
				];

				dimensionAttrIds = [
					...(props.parentDataframe ?? props.dataframe).rowEntry
						.filter((row) => {
							return row.entity_type == OptionsBuilderItemTypes.DimensionAttribute;
						})
						.map((dim) => dim.entity_id),
					...(props.parentDataframe ?? props.dataframe).columnEntry
						.filter((col) => {
							return col.entity_type == OptionsBuilderItemTypes.DimensionAttribute;
						})
						.map((dim) => dim.entity_id),
					...(props.parentDataframe ?? props.dataframe).filters
						.filter((filter) => {
							return filter.entity_type == OptionsBuilderItemTypes.DimensionAttribute;
						})
						.map((dim) => dim.entity_id),
				];

				// Remove duplicate Ids
				dimensionIds = uniq(dimensionIds);
				dimensionAttrIds = uniq(dimensionAttrIds);

				props.dimensions.forEach((dimension: TDimension): void => {
					const clonedDim = _.cloneDeep(dimension);

					if (
						dimensionIds.includes(clonedDim.id) ||
						clonedDim.dimensionAttributes.some((attr: TDimensionAttribute) =>
							dimensionAttrIds.includes(attr.id)
						)
					) {
						clonedDim.dimensionAttributes = clonedDim.dimensionAttributes.filter(
							(attr: TDimensionAttribute) => dimensionAttrIds?.includes(attr.id)
						);
						dimsToUse.push(clonedDim);
					}
				});

				setAvailableDimensions(dimsToUse);
			} else {
				setAvailableDimensions([
					defaultDateRangeDim,
					...props.dimensions.filter((dimension) => {
						return dimension.keyMeasures.some((measure) => {
							return measuresToUse.map((measure) => measure.id).includes(measure.id);
						});
					}),
				]);
			}
		}
	};

	const buildTabs = () => {
		let measuresToUse = availableMeasures.map((measure): ITabNavigationItem => {
			const isTemplateCalculatedMeasureField =
				measure.type == OptionsBuilderItemTypes.CalculatedFact;

			const draggableCalculatedData: DraggableMenuItemData = {
				id: generateGUID(),
				entity: measure,
				data: populateDataForCalculatedFact(
					measure,
					props.unitTypes.find((u) => u.id === measure.unitType)
				),
				entityType: OptionsBuilderItemTypes.KeyMeasureFact,
				itemType: 'dropdown',
				allowedZones: [DataBuilderZones.facts],
				static: false,
				icon: 'database',
			};

			return {
				id: measure.id.toString(),
				name: isTemplateCalculatedMeasureField
					? buildDraggableItem(
							draggableCalculatedData,
							measure.display_name ?? measure.name
					  )
					: measure.name,
				children: isTemplateCalculatedMeasureField
					? undefined
					: measure.keyMeasureFacts.map((fact: TKeyMeasureFact): ITabNavigationItem => {
							const data: DraggableMenuItemData = {
								id: generateGUID(),
								entity: fact,
								data: populateDataForFact(
									fact,
									props.unitTypes.find((u) => u.id === fact.unitType)
								),
								entityType: OptionsBuilderItemTypes.KeyMeasureFact,
								itemType: 'dropdown',
								allowedZones: [DataBuilderZones.facts],
								static: false,
								icon: 'database',
							};
							return {
								name: buildDraggableItem(data, fact.name),
								id: fact.id.toString(),
							};
					  }),
			};
		});

		let savedCalculatedFacts = props.parentDataframe?.datasets.filter(
			(x) => x.calculated == true
		);

		if (!savedCalculatedFacts) {
			savedCalculatedFacts = props.dataframe?.datasets.filter((x) => x.calculated == true);
		}

		const calculatedKeyMeasureParent: ITabNavigationItem = {
			id: generateGUID(),
			name: 'Calculations',
			children:
				savedCalculatedFacts &&
				savedCalculatedFacts.map((fact: TDataframeData): ITabNavigationItem => {
					const data: DraggableMenuItemData = {
						id: generateGUID(),
						entity: fact,
						data: populateDataForCalcFact(
							fact,
							props.unitTypes.find((u) => u.id === fact.unitType)
						),
						entityType: OptionsBuilderItemTypes.KeyMeasureFact,
						itemType: 'dropdown',
						allowedZones: [DataBuilderZones.facts],
						static: false,
						icon: 'database',
					};
					return {
						name: buildDraggableItem(data, fact.title ?? fact.alias!),
						id: fact.id.toString(),
					};
				}),
		};

		if (savedCalculatedFacts && savedCalculatedFacts.length > 0) {
			measuresToUse = [...measuresToUse, calculatedKeyMeasureParent];
		}

		const dimsToUse = availableDimensions.map((dimension): ITabNavigationItem => {
			const dimData: DraggableMenuItemData = {
				id: generateGUID(),
				entity: dimension,
				data: populateDataForDimension(dimension),
				entityType:
					dimension.type != OptionsBuilderItemTypes.DateSeries
						? OptionsBuilderItemTypes.Dimension
						: OptionsBuilderItemTypes.DateSeries,
				itemType: 'dropdown',
				allowedZones: [
					DataBuilderZones.rows,
					DataBuilderZones.columns,
					...(dimension.type != OptionsBuilderItemTypes.DateSeries
						? [DataBuilderZones.filters]
						: []),
				],
				static: false,
				icon: 'dataSource',
			};

			const navItem = {
				name:
					dimension.dimensionAttributes.length > 0
						? dimension.display_name ?? dimension.name
						: buildDraggableItem(dimData, dimension.name ?? dimension.display_name),
				id: dimension.id.toString(),
				children:
					dimension.dimensionAttributes.length > 0
						? dimension.dimensionAttributes.map(
								(item: TDimensionAttribute): ITabNavigationItem => {
									const data: DraggableMenuItemData = {
										id: generateGUID(),
										entity: item,
										data: populateDataForDimensionAttribute(item),
										entityType: OptionsBuilderItemTypes.DimensionAttribute,
										itemType: 'dropdown',
										allowedZones: [
											DataBuilderZones.rows,
											DataBuilderZones.columns,
											DataBuilderZones.filters,
											DataBuilderZones.drills,
										],
										static: false,
										icon: 'dataSource',
									};
									return {
										name: buildDraggableItem(data, item.name),
										id: item.id.toString(),
									};
								}
						  )
						: undefined,
			};

			if (dimension.dimensionAttributes.length > 0) {
				navItem.children?.push({
					name: buildDraggableItem(dimData, dimension.name ?? dimension.display_name),
					id: dimension.id.toString(),
				});
			}

			return navItem;
		});

		return [
			...[
				{
					label: 'Measures',
					items: measuresToUse,
				},
			],
			...[
				{
					label: props.type === DataBuilderTypes.dataframe ? 'Series' : 'Fields',
					items: dimsToUse,
				},
			],
		];
	};

	const onDragStarted = () => {
		setDragStarted(true);
	};

	const buildDraggableItem = (data: DraggableMenuItemData, name: string) => {
		return (
			<DraggableMenuItem
				data={data}
				onSetPotentialDropZones={setPotentialDropZones}
				onDragStarted={onDragStarted}
			>
				<Text component={TextVariants.h3}>{name}</Text>
			</DraggableMenuItem>
		);
	};

	const setPotentialDropZones = (data?: DraggableMenuItemData) => {
		if (data) {
			switch (data.entityType) {
				case OptionsBuilderItemTypes.KeyMeasureFact:
					setDropZonesToHighlight([DataBuilderZones.facts]);
					break;
				case OptionsBuilderItemTypes.Dimension:
					setDropZonesToHighlight([
						DataBuilderZones.rows,
						DataBuilderZones.columns,
						DataBuilderZones.filters,
						DataBuilderZones.drills,
					]);
					break;
				case OptionsBuilderItemTypes.DimensionAttribute:
					setDropZonesToHighlight([
						DataBuilderZones.rows,
						DataBuilderZones.columns,
						DataBuilderZones.filters,
						DataBuilderZones.drills,
					]);
					break;
				case OptionsBuilderItemTypes.DateSeries:
					setDropZonesToHighlight([DataBuilderZones.rows, DataBuilderZones.columns]);
					break;
			}
		} else {
			setDropZonesToHighlight([]);
		}
	};

	const onNameChange = (value: string): void => {
		if (value.trim() === '') {
			props.setValidated && props.setValidated('error');
		} else {
			props.setValidated && props.setValidated('success');
		}
		props.setName(value);
		props.setHasChanges && props.setHasChanges(true);
	};

	const PreviewClicked = () => {
		if (props.onPreview) {
			props.onPreview();
		}
	};

	const onFolderSelect = (id: string | undefined): void => {
		if (props.setFolder && id) {
			props.setFolder(props.folders.find((_: TFolder) => _.id.toString() === id));
			props.setHasChanges && props.setHasChanges(true);
		}
	};

	const dropzoneTemplate = () => {
		return (
			<DataBuilderDropGrid
				dropGridType={
					props.type === DataBuilderTypes.chart
						? DataBuilderDropGridTypes.slim
						: DataBuilderDropGridTypes.normal
				}
				builderType={props.type}
				zones={getZones()}
				zoneItemAction={droppedItemAction}
				zoneItemDateSelect={updateSelectedDate}
				zonesToHighlight={dropZonesToHighlight}
				updateZoneItem={updateDroppedItem}
				updateAllZoneItems={updateAllDroppedItems}
				updateZonesToHighlight={setPotentialDropZones}
				dataframeDatasets={props.dataframe?.datasets}
				savedDataframeDataJoins={props.savedDataframeDataJoins}
				setDeleteDataframeDataJoinIds={props.setDeleteDataframeDataJoinIds}
				setSavedDataframeDataJoins={props.setSavedDataframeDataJoins}
				fetchDataframeDataJoins={props.fetchDataframeDataJoins}
				dataframe={props.dataframe}
				facts={props.facts}
				filters={props.filters}
				name={props.name ?? props.dataframe?.name}
				rows={props.rows}
				folder={props.folder}
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				order={props.order}
				setDataframeId={props.setDataframeId}
				setDisablePreviewBtn={props.setDisablePreviewBtn}
				disableCalculatedFact={props.disableCalculatedFact}
				isLoadingKMs={isLoadingKMs}
				setIsLoadingKMs={setIsLoadingKMs}
				setMinimumOneInvalidJoin={setMinimumOneInvalidJoin}
				datePeriodSelectorLabel={props.datePeriodSelectorLabel}
				datePeriodSelectorDisabledMessage={props.datePeriodSelectorDisabledMessage}
				hideConfigureCalculationOption={props.hideConfigureCalculationOption}
				hideKeyMeasureJoinZone={props.type != DataBuilderTypes.dataframe}
			></DataBuilderDropGrid>
		);
	};

	const dropzoneDrilldownTemplate = () => {
		return (
			<DataBuilderDropGrid
				dropGridType={DataBuilderDropGridTypes.empty}
				builderType={props.type}
				zones={getDrilldownZones()}
				zoneItemAction={droppedItemAction}
				zonesToHighlight={dropZonesToHighlight}
				updateZoneItem={updateDroppedItem}
				updateAllZoneItems={updateAllDroppedItems}
				updateZonesToHighlight={setPotentialDropZones}
			></DataBuilderDropGrid>
		);
	};

	const insightsTemplate = (questions: TQuestion[], lambdaInsights?: LambdaInsightsResponse) => (
		<div>
			<div className="dv-insights">
				<div className="pull-right close-insights">
					<FontAwesomeIcon
						icon={faTimes}
						size="2x"
						onClick={() => {
							props.setInsightsOpen && props.setInsightsOpen(false);
						}}
					/>
				</div>
				<div className="insights-header">
					{' '}
					<FontAwesomeIcon
						icon={faLightbulb}
						size="2x"
						color="#0073c3"
					/>
					<Title
						headingLevel="h1"
						data-testid={`header-insights`}
					>
						Insights
					</Title>
				</div>
			</div>
			<hr />
			<br />
			<div className="dv-insights">
				<div className="alert-row">
					<b>{props.name}</b>
				</div>
			</div>
			<hr />
			<br />
			{!lambdaInsights && props.previewData?.csvData && (
				<>
					<div className="dv-insights">
						<Button
							key="generate_graph_summary"
							variant="primary"
							className="btn-graph-summary"
							onClick={getLambdaInsights}
						>
							AI * Insights
						</Button>
					</div>
					<hr />
					<br />
				</>
			)}
			{lambdaInsights && (
				<>
					<LambdaInsights
						insights={lambdaInsights}
						hasBackground={false}
					/>
					<hr />
					<br />
				</>
			)}
			{hasViewQuestionPerm && (
				<div className="dv-insights">
					<b>Discover Questions</b>
					{hasCreateQuestionPerm && (
						<Button
							key="cancel"
							variant="primary"
							className="pull-right btn-add-question"
							onClick={() => {
								setIsAddQuestionModalOpen(true);
								setFetchedEditQuestion(undefined);
							}}
						>
							+ Add
						</Button>
					)}
					<br />
					<br />
					{(questions ?? fetchedQuestions).map((question) => {
						return (
							<div>
								<label className="pr-10">{question.name}</label>
								{hasEditQuestionPerm && (
									<FontAwesomeIcon
										icon={faPencil}
										className="pr-10 pointer"
										onClick={() => {
											setFetchedEditQuestion(question);
											OpenEditQuestionModal(question.id!);
										}}
									/>
								)}
								{hasDeleteQuestionPerm && (
									<FontAwesomeIcon
										icon={faTrashAlt}
										className="pointer"
										onClick={() => {
											OpenDeleteQuestionModal(question.id!);
										}}
									/>
								)}
								<br />
								<br />
							</div>
						);
					})}
				</div>
			)}
		</div>
	);

	const handleRemoval = (data: DraggableMenuItemData, zone: DataBuilderZones) => {
		switch (zone) {
			case DataBuilderZones.facts:
				props.setFacts(
					props.facts.filter((fact) =>
						data.data && data.data.id
							? fact.data?.id != data.data?.id
							: fact.id != data.id
					)
				);
				break;
			case DataBuilderZones.rows:
				props.setRows(
					props.rows.filter(
						(row) =>
							!(row.data?.id == data.data?.id && row.entityType == data.entityType)
					)
				);
				break;
			case DataBuilderZones.columns:
				if (props.setColumns && props.columns) {
					props.setColumns(
						props.columns.filter(
							(column) =>
								!(
									column.data?.id == data.data?.id &&
									column.entityType == data.entityType
								)
						)
					);
				}
				break;
			case DataBuilderZones.filters:
				props.setFilters(
					props.filters.filter(
						(filter) =>
							!(
								filter.data?.id == data.data?.id &&
								filter.entityType == data.entityType
							)
					)
				);
				break;
			case DataBuilderZones.drills:
				if (props.setDrills && props.drills && props.drillLevel && props.drillLevel === 1) {
					props.setDrills(
						props.drills.filter(
							(drill) =>
								!(
									drill.data?.id == data.data?.id &&
									drill.entityType == data.entityType
								)
						)
					);
				}
				break;
		}
		props.setHasChanges && props.setHasChanges(true);
	};

	const droppedItemAction = (
		key: string,
		droppedItem: DraggableMenuItemData,
		zone: DataBuilderZones
	) => {
		if (key.includes('remove')) {
			handleRemoval(droppedItem, zone);
			return;
		}
		setCurrentItem(droppedItem);
		setCurrentZone(zone);

		switch (key) {
			case KeyMeasureFactOptions.Options:
				setFactOptionsModalOpen(true);
				return;
			case KeyMeasureFactOptions.Filters:
				setFactFilterModalOpen(true);
				return;
			case KeyMeasureFactOptions.ChangeUnitType:
				setUnitTypeModalOpen(true);
				return;
			case DimensionOptions.Options:
				setDimensionOptionsModalOpen(true);
				return;
			case DimensionOptions.EnableDrilldown:
				return;
			case FilterOptions.Click:
				setFilterModalOpen(true);
				return;
			case DimensionOptions.BreakoutGroups:
				applyBreakGroups(zone, droppedItem);
				return;
			case KeyMeasureFactOptions.Calculation:
				if (props.disableCalculatedFact) {
					return;
				}
				// eslint-disable-next-line no-case-declarations
				let existingCalculatedFactDataset = props.dataframe?.datasets.find(
					(x) => x.calculated && x.id == droppedItem.data?.id
				);

				if (
					!existingCalculatedFactDataset &&
					props.calculatedFact &&
					droppedItem.data?.title != defaultCalculatedFact.display_name
				) {
					const dataframeId = props.dataframe?.id ?? +(frameId ?? 0);
					existingCalculatedFactDataset = {
						alias: props.calculatedFact.data?.alias,
						calculated: true,
						formula_operation: props.calculatedFact.data?.formula_operation,
						formula_variable_1: props.calculatedFact.data?.formula_variable_1,
						formula_variable_2: props.calculatedFact.data?.formula_variable_2,
						title: props.calculatedFact.data?.title,
						unitType: props.calculatedFact.data?.unitType,
						dataframe: dataframeId,
						id: 0,
						keyMeasureFact: undefined,
					};
				}

				if (existingCalculatedFactDataset) {
					setCurrentCalculatedFactDataset(existingCalculatedFactDataset);
					props.setCurrentCalculatedFactId &&
						props.setCurrentCalculatedFactId(existingCalculatedFactDataset.id);
				} else {
					setCurrentCalculatedFactDataset(undefined);
					props.setCalculatedFactAlias && props.setCalculatedFactAlias(undefined);
					props.setCurrentCalculatedFactId && props.setCurrentCalculatedFactId(undefined);
				}

				props.setCalculationModalOpen && props.setCalculationModalOpen(true);
				return;
		}
	};

	const updateDroppedItem = (
		droppedItem: DraggableMenuItemData,
		destinationZone: DataBuilderZones,
		sourceZone?: DataBuilderZones
	) => {
		switch (destinationZone) {
			case DataBuilderZones.facts: {
				if (
					droppedItem.entityType === OptionsBuilderItemTypes.KeyMeasureFact &&
					droppedItem.allowedZones.includes(DataBuilderZones.facts)
				) {
					addFact(droppedItem, destinationZone);
				}
				break;
			}
			case DataBuilderZones.rows: {
				if (
					droppedItem.entityType != OptionsBuilderItemTypes.KeyMeasureFact &&
					droppedItem.allowedZones.includes(DataBuilderZones.rows)
				) {
					if (destinationZone != sourceZone) {
						if (!checkRowLimit()) {
							break;
						}
					}

					if (
						!sourceZone &&
						(checkExistingDrill(droppedItem) || checkExistingColumn(droppedItem))
					) {
						break;
					}

					droppedItem = resetBreakGroup(droppedItem);
					addRow(droppedItem, destinationZone);
				}

				break;
			}
			case DataBuilderZones.columns: {
				if (
					droppedItem.entityType != OptionsBuilderItemTypes.KeyMeasureFact &&
					droppedItem.allowedZones.includes(DataBuilderZones.columns)
				) {
					if (!sourceZone && checkExistingRow(droppedItem)) {
						break;
					}

					droppedItem = resetBreakGroup(droppedItem);
					addColumn(droppedItem, destinationZone);
				}
				break;
			}
			case DataBuilderZones.filters: {
				if (
					droppedItem.entityType != OptionsBuilderItemTypes.KeyMeasureFact &&
					droppedItem.allowedZones.includes(DataBuilderZones.filters)
				) {
					addFilter(droppedItem, destinationZone);
				}
				break;
			}
			case DataBuilderZones.drills: {
				if (
					droppedItem.entityType != OptionsBuilderItemTypes.KeyMeasureFact &&
					droppedItem.allowedZones.includes(DataBuilderZones.drills)
				) {
					if (checkExistingRow(droppedItem)) {
						break;
					}

					addDrill(droppedItem, destinationZone);
				}
				break;
			}
		}

		if (
			sourceZone &&
			sourceZone != destinationZone &&
			droppedItem.allowedZones.includes(destinationZone)
		) {
			handleRemoval(droppedItem, sourceZone);
		}
	};

	const updateAllDroppedItems = (
		droppedItems: DraggableMenuItemData[],
		zone: DataBuilderZones
	) => {
		switch (zone) {
			case DataBuilderZones.facts: {
				props.setFacts(droppedItems);
				break;
			}
			case DataBuilderZones.rows: {
				droppedItems = resetBreakGroups(droppedItems);
				props.setRows(droppedItems);
				break;
			}
			case DataBuilderZones.columns: {
				droppedItems = resetBreakGroups(droppedItems);
				props.setColumns && props.setColumns(droppedItems);
				break;
			}
			case DataBuilderZones.filters: {
				props.setFilters(droppedItems);
				break;
			}
			case DataBuilderZones.drills: {
				props.setDrills && props.setDrills(droppedItems);
				break;
			}
		}
		props.setHasChanges && props.setHasChanges(true);
	};

	const addFact = (fact: DraggableMenuItemData, zone: DataBuilderZones) => {
		if (
			!props.facts.find(
				(f) =>
					f.data?.id === fact.data?.id ||
					(fact.data?.calculated && fact.data.title == f.data?.title)
			) ||
			fact.data?.id == -1
		) {
			fact.currentZone = zone;
			props.setFacts([...props.facts, fact]);
			props.setHasChanges && props.setHasChanges(true);
		}
	};

	const addRow = (row: DraggableMenuItemData, zone: DataBuilderZones) => {
		if (
			!props.rows?.find(
				(f) => f.data?.id === row?.data?.id && f.entityType === row.entityType
			)
		) {
			row.currentZone = zone;
			props.setRows([...props.rows, row]);
			props.setHasChanges && props.setHasChanges(true);
		}
	};

	const addColumn = (column: DraggableMenuItemData, zone: DataBuilderZones) => {
		if (
			props.columns &&
			!props.columns.find(
				(f) => f.data?.id === column.data?.id && f.entityType === column.entityType
			)
		) {
			column.currentZone = zone;
			props.setColumns && props.setColumns([...props.columns, column]);
			props.setHasChanges && props.setHasChanges(true);
		}
	};

	const addFilter = (filter: DraggableMenuItemData, zone: DataBuilderZones) => {
		if (
			!props.filters.find(
				(f) => f.data?.id === filter.data?.id && f.entityType === filter.entityType
			)
		) {
			filter.currentZone = zone;
			props.setFilters([...props.filters, filter]);
			props.setHasChanges && props.setHasChanges(true);
		}
	};

	const addDrill = (drill: DraggableMenuItemData, zone: DataBuilderZones) => {
		if (
			props.drills &&
			!props.drills?.find(
				(d) => d.data?.id === drill.data?.id && d.entityType === drill.entityType
			)
		) {
			drill.currentZone = zone;
			props.setDrills && props.setDrills([...props.drills, drill]);
			props.setHasChanges && props.setHasChanges(true);
		}
	};

	const updateSelectedDate = (startDateRange: TNewDateRange, endDateRange: TNewDateRange) => {
		if (props.setSelectedDate) {
			props.setSelectedDate({
				begin_date: startDateRange.begin_date,
				end_date: endDateRange.end_date,
				period: startDateRange.period,
				sequence: startDateRange.sequence,
			});
		}
	};

	const getZones = () => {
		const zones = [
			{
				label: 'Facts',
				zoneItems: props.facts,
				zoneType: DataBuilderZones.facts,
				hasBreakoutGroups: false,
			},
			{
				label: 'Fields',
				zoneItems: props.rows,
				zoneType: DataBuilderZones.rows,
				hasBreakoutGroups: props.type === DataBuilderTypes.report,
			},
			{
				label: 'Filters',
				zoneItems: props.filters,
				zoneType: DataBuilderZones.filters,
				hasBreakoutGroups: false,
			},
		];

		if (props.type === DataBuilderTypes.report) {
			zones.splice(2, 0, {
				label: 'Pivots',
				zoneItems: props.columns ?? [],
				zoneType: DataBuilderZones.columns,
				hasBreakoutGroups: props.type === DataBuilderTypes.report,
			});
		}

		return zones;
	};

	const getDrilldownZones = () => {
		return [
			{
				label: '',
				zoneItems: props.drills ?? [],
				zoneType: DataBuilderZones.drills,
				hasBreakoutGroups: false,
			},
		];
	};

	const onModalClose = (): void => {
		closeAllModals();
	};

	const closeAllModals = (): void => {
		setFactFilterModalOpen(false);
		setUnitTypeModalOpen(false);
		setFactOptionsModalOpen(false);
		setDimensionOptionsModalOpen(false);
		setFilterModalOpen(false);
	};

	const onModalSave = (item: DraggableMenuItemData) => {
		switch (currentZone) {
			case DataBuilderZones.facts: {
				props.setFacts(
					props.facts.map((fact) => {
						if (item.data?.id === fact.data?.id) {
							fact.data = item.data;
						}
						return fact;
					})
				);
				break;
			}
			case DataBuilderZones.rows: {
				props.setRows(
					props.rows.map((row) => {
						if (item.data?.id === row.data?.id && item.entityType === row.entityType) {
							row.data = item.data;
						}
						return row;
					})
				);
				break;
			}
			case DataBuilderZones.columns: {
				if (props.setColumns && props.columns) {
					props.setColumns &&
						props.setColumns(
							props.columns.map((column) => {
								if (
									item.data?.id === column.data?.id &&
									item.entityType === column.entityType
								) {
									column.data = item.data;
								}
								return column;
							})
						);
				}
				break;
			}
			case DataBuilderZones.filters: {
				props.setFilters(
					props.filters.map((filter) => {
						if (
							item.data?.id === filter.data?.id &&
							item.entityType === filter.entityType
						) {
							filter.data = item.data;
						}
						return filter;
					})
				);
				break;
			}
		}
		props.setHasChanges && props.setHasChanges(true);
		closeAllModals();
	};

	const onFilterModalSave = (item: DraggableMenuItemData): void => {
		props.setFilters(
			props.filters.map((filter) => {
				if (item.data?.id === filter.data?.id && item.entityType === filter.entityType) {
					filter.data = item.data;
				}
				return filter;
			})
		);
		props.setHasChanges && props.setHasChanges(true);
		closeAllModals();
	};

	const applyBreakGroups = (zone: DataBuilderZones, data: DraggableMenuItemData): void => {
		const listToSet: DraggableMenuItemData[] = [];

		if (data.data) {
			data.data.totaled = !data.data.totaled;
		}

		const firstIndex =
			props[zone]?.findIndex(
				(item) => item.id === data.id && item.entityType === item.entityType
			) ?? 0;

		if (data.data?.totaled) {
			props[zone]?.forEach((item, index) => {
				if (index < firstIndex && item.data) {
					item.data.totaled = true;
				}
				listToSet.push(item);
			});
		} else {
			props[zone]?.forEach((item, index) => {
				if (index > firstIndex && item.data) {
					item.data.totaled = false;
				}
				listToSet.push(item);
			});
		}

		switch (zone) {
			case DataBuilderZones.rows: {
				props.setRows(listToSet);
				break;
			}
			case DataBuilderZones.columns: {
				props.setColumns && props.setColumns(listToSet);
				break;
			}
		}

		props.setHasChanges && props.setHasChanges(true);
	};

	const resetBreakGroup = (droppedItem: DraggableMenuItemData) => {
		if (droppedItem.data) {
			droppedItem.data.totaled = false;
		}

		return droppedItem;
	};

	const resetBreakGroups = (droppedItems: DraggableMenuItemData[]) => {
		return droppedItems.map((item) => {
			if (item.data) {
				item.data.totaled = false;
			}
			return item;
		});
	};

	const getDiscoverQuestions = (reloadInsights = false) => {
		if (chartId) {
			setConfigForInsights([], undefined, true);

			Question.GetAll(parseInt(chartId))
				.then((questions) => {
					if (reloadInsights) {
						setFetchedQuestions(questions);
						setConfigForInsights(questions);
					}
				})
				.catch(() => {
					addToast(
						'An error occured while fetching Discover Questions',
						AlertVariant.danger
					);
					setSidebarConfig({
						...sideBarConfig,
						rightSideBar: undefined,
						isRightSideBarLoading: false,
					});
				});
		}
	};

	const getLambdaInsights = () => {
		if (props.lambdaInsightsRequest) {
			setConfigForInsights(fetchedQuestions, undefined, true);

			Lambda.GetInsights(props.lambdaInsightsRequest)
				.then((insights) => {
					if (insights.success) {
						if (typeof insights.data === 'string') {
							insights.data = JSON.parse(insights.data) as LambdaInsightsResponse;
						}
						setConfigForInsights(fetchedQuestions, insights.data);
					} else {
						addToast(insights.message, AlertVariant.danger);
						setConfigForInsights(fetchedQuestions);
					}
				})
				.catch((): void => {
					addToast('Error fetching insights.', AlertVariant.danger);
					setConfigForInsights(fetchedQuestions);
				});
		}
	};

	const setConfigForInsights = (
		questions: TQuestion[],
		lambdaInsights?: LambdaInsightsResponse,
		loading = false
	) => {
		setSidebarConfig({
			...sideBarConfig,
			rightSideBar: insightsTemplate(questions, lambdaInsights),
			isRightSideBarLoading: loading,
		});
	};

	const handleReloadInsights = () => {
		getDiscoverQuestions(true);
	};

	const OpenEditQuestionModal = (questionId: number) => {
		if (chartId) {
			void Question.Get(questionId, ['tags']).then((response) => {
				setFetchedEditQuestion(response);
				setIsAddQuestionModalOpen(true);
			});
		}
	};

	const OpenDeleteQuestionModal = (questionId: number) => {
		if (chartId) {
			setDeleteQuestionId(questionId);
			setIsDeleteQuestionModalOpen(true);
		}
	};

	const DeleteQuestion = () => {
		if (deleteQuestionId != 0) {
			Question.Delete(deleteQuestionId)
				.then(() => {
					closeQuestionDeleteModal();
					getDiscoverQuestions(true);
					addToast('Deleted Discover Question Succesfully', AlertVariant.success);
				})
				.catch(() => {
					addToast(
						'An error occured while Deleting Discover Question',
						AlertVariant.danger
					);
				});
		}
	};

	const closeQuestionModal = (hasAddedQuestion = false) => {
		setIsAddQuestionModalOpen(false);

		if (hasAddedQuestion) {
			getDiscoverQuestions(true);
		}
	};

	const closeQuestionDeleteModal = () => {
		setIsDeleteQuestionModalOpen(false);
	};

	const closeConfigureCalculationModal = () => {
		props.setCalculationModalOpen && props.setCalculationModalOpen(false);
	};

	const checkExistingDrill = (droppedItem: DraggableMenuItemData) => {
		return (
			props.drills &&
			props.drills.some(
				(drill) =>
					drill.data?.id === droppedItem.data?.id &&
					drill.entityType === droppedItem.entityType
			)
		);
	};

	const checkExistingRow = (droppedItem: DraggableMenuItemData) => {
		return props.rows.some(
			(row) =>
				row.data?.id === droppedItem.data?.id && row.entityType === droppedItem.entityType
		);
	};

	const checkExistingColumn = (droppedItem: DraggableMenuItemData) => {
		return props.columns?.some(
			(col) =>
				col.data?.id === droppedItem.data?.id && col.entityType === droppedItem.entityType
		);
	};

	const checkRowLimit = () => {
		//Prevent more fields from being dropped if the number of fields is != rowLimit
		if (props.rowLimit && props.rows.length == props.rowLimit) {
			addToast(
				`A maximum of 2 ${
					props.type === DataBuilderTypes.report ? 'rows' : 'fields'
				} is allowed`,
				AlertVariant.danger
			);

			return false;
		}

		return true;
	};

	const beforeDragTemplate = (
		<div className="no-data-container">
			<TextContent className="no-data-headers">
				<Text component={TextVariants.h1}>Drag & Drop Data</Text>
				<Text component={TextVariants.small}>
					Choose the chart, table or data from the left sidebar to populate your report
				</Text>
			</TextContent>
		</div>
	);

	const dataItemOptionsTemplate = (
		<Form>
			<div className="row-container">
				<div className="start-container">
					<FormGroup
						label={props.formLabel}
						type="text"
						isRequired
						fieldId="name"
						helperTextInvalid={props.formLabel + ' is required'}
						helperTextInvalidIcon={<ExclamationCircleIcon />}
						validated={props.validated}
					>
						<TextInput
							isRequired
							type="text"
							aria-label={'Enter a ' + props.formLabel}
							placeholder={'Enter a ' + props.formLabel}
							value={props.name}
							validated={props.validated}
							onChange={(value) => {
								onNameChange(value);
							}}
						/>
					</FormGroup>
					<FormGroup
						label={<FontAwesomeIcon icon={faFolder} />}
						type="text"
						fieldId="folder"
						className="folder-container"
					>
						<TreeViewSelect
							onSelect={(e: any, item: TreeViewDataItem) => {
								if (item && item.id && item.id != '-1') {
									onFolderSelect(item.id ?? '');
								}
							}}
							data={
								props.folders
									? props.folders.map((_: TFolder) =>
											BuildTreeViewItem(_, _.items)
									  )
									: []
							}
							placeHolderText={props.folder ? props.folder.name : 'Select a Folder'}
						/>
					</FormGroup>
				</div>
				<div className="end-container">
					{props.onPreview && (
						<Button
							variant="primary"
							onClick={PreviewClicked}
							isDisabled={props.disablePreviewBtn === true}
						>
							Preview
						</Button>
					)}
					{showSaveButton && (
						<Button
							variant="primary"
							onClick={() => props.onSave()}
							isDisabled={
								props.validated !== 'success' ||
								(props.drillLevel != undefined && props.drillLevel > 1) ||
								hasInvalidJoins ||
								isLoadingKMs
							}
						>
							{props.isWidgetSave ? 'Save and Return to Dashboard' : 'Save'}
						</Button>
					)}
				</div>
			</div>
			{(frameId || chartId || reportId) && (
				<EntityMiscButtons
					entityType={chartId ? 'Chart' : 'Dataframe'}
					entityName={props.name}
					canShare={props.sharedPermissions.canShare}
					exportData={props.previewData}
					limitReached={props.showLimitWarning}
					favorite={props.favorite}
					setFavorite={props.setFavorite}
				/>
			)}
		</Form>
	);

	const drilldownTemplate = (
		<div>
			<div className="dv-drill">
				<div className="pull-right close-drill">
					<FontAwesomeIcon
						icon={faTimes}
						size="2x"
						onClick={() => {
							props.setDrillSequenceOpen && props.setDrillSequenceOpen(false);
						}}
					/>
				</div>
				<div className="drill-header">
					{' '}
					<FontAwesomeIcon
						icon={faChevronsDown}
						color="#0073c3"
					/>
					<Title
						headingLevel="h1"
						data-testid={`header-drill`}
					>
						Drill Sequence
					</Title>
				</div>
				<small>Drag series to add to drill sequence</small>
			</div>
			<div>
				<hr />
			</div>
			{props.isLoadingDrillDown && (
				<div className="pt-12">
					<Loader />
				</div>
			)}
			{!props.isLoadingDrillDown && (
				<div className="drill-items-container">{dropzoneDrilldownTemplate()}</div>
			)}
		</div>
	);

	return (
		<div className={`data-builder-container ${dragStarted ? '' : 'h-100'}`}>
			{!dragStarted ? (
				beforeDragTemplate
			) : (
				<>
					{dataItemOptionsTemplate}
					{dropzoneTemplate()}
				</>
			)}
			<>
				<>
					{unitTypeModalOpen && (
						<ChangeUnitTypeModal
							unitTypes={props.unitTypes}
							fact={currentItem ?? null}
							handleClose={onModalClose}
							handleSave={onModalSave}
						/>
					)}
					{factOptionsModalOpen && (
						<FactOptionsModal
							periods={props.periods}
							fact={currentItem ?? null}
							numDecimals={
								props.unitTypes.find((u) => u.id === currentItem?.data?.unitType)
									?.precision
							}
							handleClose={onModalClose}
							handleSave={onModalSave}
						/>
					)}
					{factFilterModalOpen && (
						<FactFilterOptionsModal
							fact={currentItem ?? null}
							handleClose={onModalClose}
							handleSave={onModalSave}
						/>
					)}
				</>
				<>
					{dimensionOptionsModalOpen && (
						<DimensionOptionsModal
							dimension={currentItem ?? null}
							handleSave={onModalSave}
							handleClose={onModalClose}
						/>
					)}
				</>
				<>
					{filterModalOpen && (
						<FilterModal
							filter={currentItem ?? null}
							handleSave={onFilterModalSave}
							handleClose={onModalClose}
						/>
					)}
				</>
				<>
					<DiscoverAddQuestionModal
						isOpen={isAddQuestionModalOpen}
						onClose={closeQuestionModal}
						chartId={chartId ? parseInt(chartId) : 0}
						fetchedQuestion={fetchedEditQuestion}
						refetchQuestions={handleReloadInsights}
					/>
				</>
				<>
					<DeleteConfirmationModal
						isOpen={isDeleteQuestionModalOpen}
						onClose={closeQuestionDeleteModal}
						onSubmit={DeleteQuestion}
					/>
				</>
				<>
					<ConfigureCalculationModal
						isOpen={props.calculationModalOpen ?? false}
						onClose={closeConfigureCalculationModal}
						onSubmit={props.saveCalculatedKMF}
						dataframe={props.dataframe}
						keyMeasures={props.measures}
						facts={props.facts}
						operatorText={props.operatorText}
						setOperatorText={props.setOperatorText}
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						KMF1={props.KMF1}
						setKMF1={props.setKMF1}
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						KMF2={props.KMF2}
						setKMF2={props.setKMF2}
						calculatedFactAlias={props.calculatedFactAlias}
						setCalculatedFactAlias={props.setCalculatedFactAlias}
						handleAliasInputChange={props.handleAliasInputChange}
						validatedAlias={props.validatedAlias}
						setValidatedAlias={props.setValidatedAlias}
						existingCalculatedDataset={currentCalculatedFactDataset}
					/>
				</>
			</>
		</div>
	);
};

export default ZiDataBuilderV2;
